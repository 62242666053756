<template>
	<div class="flex flex-col h-full">
		<!-- Messages Display -->
		<div class="flex-1 overflow-y-auto p-4">
			<div v-for="(message, index) in messages" :key="index" class="mb-4">
				<!-- User Message -->
				<div v-if="message.sender === 'user'" class="flex justify-end">
					<div class="bg-blue-500 text-white p-3 rounded-lg max-w-md">
						<!-- Display user's message -->
						<div v-html="message.html"></div>
					</div>
				</div>
				<!-- Assistant Message -->
				<div v-else class="flex justify-start">
					<div class="bg-gray-200 text-gray-900 p-3 rounded-lg max-w-lg">
						<!-- Display assistant's message -->
						<div v-html="message.html"></div>
					</div>
				</div>
			</div>
			<div ref="chatFooter"></div>
		</div>
		<!-- Message Input -->
		<div class="p-4 border-t">
			<form @submit.prevent="sendMessage" class="flex">
				<textarea v-model="newMessage" placeholder="Type your message..." class="flex-1 border rounded-l-lg p-2"
					:disabled="isLoading" required></textarea>

				<button type="submit" class="bg-indigo-600 text-white px-4 py-2 rounded-r-lg hover:bg-indigo-700"
					:disabled="isLoading">
					Send
				</button>
			</form>
		</div>
	</div>
</template>

<script setup>
/* eslint-disable */
import { ref, watch, onUnmounted, onMounted, defineProps, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { marked } from 'marked';

const props = defineProps({
	assistant: {
		type: Object,
		required: true,
	},
});

const store = useStore();
const accessToken = store.state.user.accessToken;
const toast = useToast();
const messages = ref([]);
const newMessage = ref('');
const sessionToken = ref('');
const isLoading = ref(false);

const chatFooter = ref(null);

watch(
	() => props.assistant,
	async () => {
		messages.value = [];
		newMessage.value = '';
		sessionToken.value = '';
		await initChat();
	}
);

const scrollToBottom = () => {
	nextTick(() => {
		const el = chatFooter.value;
		if (el) {
			el.scrollIntoView({ behavior: 'smooth' });
		}
	});
};

const initChat = async () => {
	try {
		const response = await fetch(`/v1/api/assistant/${props.assistant.token}/initchat`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
		});
		sessionToken.value = await response.text();
	} catch (error) {
		toast.error('Failed to initialize chat');
		console.error('Error initializing chat:', error);
	}
};

const sendMessage = async () => {
	if (!newMessage.value.trim()) {
		return;
	}

	// Add user's message to the chat
	const userMessage = {
		sender: 'user',
		text: newMessage.value,
		html: marked.parseInline(newMessage.value),
	};
	messages.value.push(userMessage);

	isLoading.value = true;
	const payload = {
		role: 'user',
		content: newMessage.value,
	};
	newMessage.value = '';

	try {
		await startEventStream(payload);
	} catch (error) {
		console.error('Error sending message:', error);
		toast.error('Failed to send message.');
		// Remove the user's message if there's an error
		messages.value.pop();
		isLoading.value = false;
	}
};

const startEventStream = async (payload) => {
	const url = `/v1/api/assistant/${props.assistant.token}/message/${sessionToken.value}`;

	try {
		const response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${accessToken}`,
			},
			body: JSON.stringify(payload),
		});

		if (!response.ok || !response.body) {
			throw new Error('Failed to start event stream');
		}

		const reader = response.body.getReader();
		const decoder = new TextDecoder('utf-8');
		let buffer = '';

		// Add assistant's message placeholder
		const assistantMessage = {
			sender: 'assistant',
			text: '',
			html: '',
		};
		messages.value.push(assistantMessage);

		while (true) {
			const { done, value } = await reader.read();
			if (done) {
				isLoading.value = false;
				break;
			}

			const chunk = decoder.decode(value, { stream: true });
			buffer += chunk;

			const messagesArray = buffer.split('\n\n');
			for (let i = 0; i < messagesArray.length - 1; i++) {
				await processSSEChunk(messagesArray[i]);
			}
			buffer = messagesArray[messagesArray.length - 1];
		}
	} catch (error) {
		console.error('Error in SSE processing:', error);
		isLoading.value = false;
	} finally {
		const lastMessage = messages.value[messages.value.length - 1];
		if (lastMessage) {
			lastMessage.state = 'done';
			messages.value[messages.value.length - 1] = { ...lastMessage };
		}
		isLoading.value = false;
	}
};

const processSSEChunk = async (chunk) => {
	const lines = chunk.split('\n').filter((line) => line.trim() !== '');

	for (const line of lines) {
		if (line.startsWith('data:')) {
			try {
				const dataContent = line.substring(5).trim();
				if (dataContent === '[DONE]') {
					// Handle the end of the stream if necessary
					continue;
				}
				// Parse the JSON content

				const jsonData = JSON.parse(dataContent);
				const content = jsonData.content;

				const lastMessage = messages.value[messages.value.length - 1];

				if (lastMessage) {
					lastMessage.state = 'streaming';
					lastMessage.text = lastMessage.text ?? '';
					lastMessage.text += content;
					lastMessage.html = marked.parse(lastMessage.text);
					messages.value[messages.value.length - 1] = { ...lastMessage };
				}
			} catch (error) {
				console.error('Error processing SSE line:', error, 'Line:', line);
			}
		}
	}

	scrollToBottom();
};

onMounted(() => {
	initChat();
});

onUnmounted(() => {
	// Clean up if necessary
});
</script>

<style scoped>
/* Optional additional styling */
</style>