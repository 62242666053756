<template>
	<div class="bg-white p-6 rounded-lg shadow-md">
		<div class="prose max-w-none">
			<h1>How to Use the Solaris Role-Playing Chat API</h1>
			<p>
				This document provides a step-by-step guide on how to use the Solaris Role-Playing chat API, focusing on
				the
				<code>/v1/api/**</code> endpoints. It covers the entire flow from creating a character to interacting in
				chat sessions and generating images.
			</p>

			<p>
				Access tokens are required for all requests. You can obtain an access token by signing up on the Solaris
				then at the top right corner of the page click on the profile icon and select "API Access" from the
				dropdown menu.
				You can access the API access screen also by clicking <a href="{{ appUrl }}/usermanagement"
					target="_blank">here</a>.
			</p>

			<h2>Base URL</h2>
			<pre><code>{{ appUrl }}/v1/api/</code></pre>

			<h2>Authentication</h2>
			<p>
				All requests to the API require authentication. Include the <code>Authorization</code> header with your
				access token:
			</p>
			<pre><code>Authorization: Bearer YOUR_ACCESS_TOKEN</code></pre>
			<h2>Application Basics</h2>
			<h3>Role Character</h3>
			<p>
				A role character is a fictional persona created for the role-playing game. Each character has a unique
				name, a brief description, associated tags,
				and an image generation model ID. These characters interact with players in chat sessions, providing a
				dynamic and immersive experience.
			</p>

			<h3>Player</h3>
			<p>
				Players are essentially users of the role-playing game, identified by their username. Note that the
				Solaris platform does not handle player authentication.
				Players are represented solely by their username and unique token, ensuring that all player data remains
				anonymous.
				The reason for this is to maintain privacy and security while allowing players to engage in role-playing
				activities.
				The purpose of the player token is to track the player's interactions and chat sessions.
			</p>

			<h3>Chat Session</h3>
			<p>
				A chat session is a conversation between a player and a role character. It allows players to interact
				with characters in real-time,
				creating a dynamic and engaging role-playing experience. Chat sessions are initiated by the player and
				character tokens.
				Each chat session has a unique token that can be used to send messages and retrieve chat history.
			</p>

			<h3>Message</h3>
			<p>
				Messages are the primary means of communication within a chat session. Players and characters exchange
				messages to progress the story,
				ask questions, or engage in role-playing activities. Messages includes text and optionally <a
					href="#title-image-generation">image prompts</a> which is used to generate images.
				Each message has a unique token that can be used to generate images or retrieve message details.
			</p>

			<h3 id="title-image-generation">Image Generation</h3>
			<p>
				Image generation is a key feature of the Solaris Role-Playing chat API. It allows characters to create
				visual representations of their world, actions, and interactions.
				Images are generated using pre-trained models and can be customized to suit the needs of the
				role-playing characters.
				Image generation is triggered by specific message prompts and can produce a variety of images based on
				the context. Each message can contain image prompts wrapped in
				<code>&lt;GenerateImage&gt;&lt;/GenerateImage&gt;</code> tags. If no image prompts are present, images
				can be generated based on the message content.
				For to generate images, it's necessary to have an image generation model ID attached to the role
				character.
				Since image generation is computationally intensive, it may take some time to process and return the
				images.
			</p>


			<h2>1. Creating a Role Character</h2>
			<p>To create a new role character, send a <code>POST</code> request to:</p>
			<pre><code>{{ appUrl }}/v1/api/rolecharacter</code></pre>

			<h3>Request Body</h3>
			<p>Provide the following JSON payload:</p>
			<pre><code class="language-json">{
	"name": "Character Name",
	"brief": "Brief description of the character",
	"tags": "comma,separated,tags",
	"imageGenerationModelId": "ImageGenerationModelID"
	}</code></pre>

			<ul class="mt-10">
				<li class="mt-5">
					<span class="text-sm font-semibold font-mono pr-2">name</span> <code class="text-sm">String</code>  <code class="text-sm text-red-600">Required</code>
					<p class="mt-1">
						The name of the character.
					</p>
				</li>
				<li class="mt-5">
					<span class="text-sm font-semibold font-mono pr-2">brief</span> <code class="text-sm">String</code> <code class="text-sm text-red-600">Required</code>
					<p class="mt-1">
						A brief description or background information about the character. This information defines the character's persona 
						and influences interactions with players. It helps the language and image generation models understand the character's 
						personality, enabling them to generate appropriate responses and visuals.
					</p>
				</li>
				<li class="mt-5">
					<span class="text-sm font-semibold font-mono pr-2">tags</span> <code class="text-sm">String</code> 
					<p class="mt-1">
						A comma-separated list of tags associated with the character. This is also helps to language and image models to understand the character better.
					</p>
				</li>
				<li class="mt-5">
					<span class="text-sm font-semibold font-mono pr-2">imageGenerationModelId</span> <code class="text-sm">Integer</code> 
					<p class="mt-1">
						The ID of the image generation model to be used for creating images.
					</p>
				</li>
			</ul>


			<h3>Example</h3>
			<pre><code class="language-shell">curl -X POST '/v1/api/rolecharacter' \
	-H 'Content-Type: application/json' \
	-H 'Authorization: Bearer YOUR_ACCESS_TOKEN' \
	-d '{
		"name": "Aria",
		"brief": "A skilled archer with a mysterious past.",
		"tags": "archer,mysterious,forest",
		"imageGenerationModelId": "42"
	}'</code></pre>

			<h3>Response</h3>
			<p>A successful response returns the details of the created character, including a unique
				<code>token</code>.
			</p>

			<h2>2. Creating a Player</h2>
			<p>Before starting a chat, create a player by sending a <code>POST</code> request to:</p>
			<pre><code>/v1/api/player</code></pre>

			<h3>Request Body</h3>
			<pre><code class="language-json">{
	"name": "Player Name"
	}</code></pre>

			<h3>Example</h3>
			<pre><code class="language-shell">curl -X POST '/v1/api/player' \
	-H 'Content-Type: application/json' \
	-H 'Authorization: Bearer YOUR_ACCESS_TOKEN' \
	-d '{
		"name": "PlayerOne"
	}'</code></pre>

			<h3>Response</h3>
			<p>The response includes the player's details with a unique <code>token</code>.</p>

			<h2>3. Creating a Chat Session</h2>
			<p>Start a new chat session between the player and the role character using a <code>POST</code> request to:
			</p>
			<pre><code>/v1/api/chat</code></pre>

			<h3>Request Body</h3>
			<pre><code class="language-json">{
	"playerToken": "PlayerToken",
	"roleCharacterToken": "RoleCharacterToken",
	"imageGenerationModelName": "ImageGenerationModelName"
	}</code></pre>

			<p>
				<strong>Parameters:</strong>
			</p>
			<ul>
				<li><code>playerToken</code>: Token obtained when creating the player.</li>
				<li><code>roleCharacterToken</code>: Token from the created role character.</li>
				<li><code>imageGenerationModelName</code>: Name of the image generation model.</li>
			</ul>

			<h3>Example</h3>
			<pre><code class="language-shell">curl -X POST '/v1/api/chat' \
	-H 'Content-Type: application/json' \
	-H 'Authorization: Bearer YOUR_ACCESS_TOKEN' \
	-d '{
		"playerToken": "player123",
		"roleCharacterToken": "character456",
		"imageGenerationModelName": "modelName"
	}'</code></pre>

			<h3>Response</h3>
			<p>The response contains the chat session details, including a unique <code>chatToken</code> for further
				interactions.</p>

			<h2>4. Sending Messages in a Chat</h2>
			<p>To send a message in an existing chat session, use:</p>
			<pre><code>/v1/api/chat/{chatToken}/message</code></pre>
			<p>Replace <code>{chatToken}</code> with your chat's token.</p>

			<h3>Request Body</h3>
			<pre><code class="language-json">{
	"message": "Your message content here."
	}</code></pre>

			<h3>Example</h3>
			<pre><code class="language-shell">curl -X POST '/v1/api/chat/{chatToken}/message' \
	-H 'Content-Type: application/json' \
	-H 'Authorization: Bearer YOUR_ACCESS_TOKEN' \
	-d '{
		"message": "Hello, how are you?"
	}'</code></pre>

			<h3>Response</h3>
			<p>A successful response returns the message details, possibly including resources if any images are
				generated.</p>

			<h2>5. Retrieving Chat Messages</h2>
			<p>To get all messages in a chat session, send a <code>GET</code> request to:</p>
			<pre><code>/v1/api/chat/{chatToken}</code></pre>

			<h3>Example</h3>
			<pre><code class="language-shell">curl -X GET '/v1/api/chat/{chatToken}' \
	-H 'Authorization: Bearer YOUR_ACCESS_TOKEN'</code></pre>

			<h3>Response</h3>
			<p>The response includes the chat details along with a list of messages exchanged.</p>

			<h2>6. Generating Images</h2>
			<p>Certain messages may include prompts for image generation. To generate an image based on a message, use:
			</p>
			<pre><code>/v1/api/chat/{chatToken}/message/{messageToken}/generateimage</code></pre>
			<p>
				Replace <code>{chatToken}</code> with your chat session token and <code>{messageToken}</code> with the
				token of the message containing the image prompt.
			</p>

			<h3>Example</h3>
			<pre><code class="language-shell">curl -X GET '/v1/api/chat/{chatToken}/message/{messageToken}/generateimage' \
	-H 'Authorization: Bearer YOUR_ACCESS_TOKEN'</code></pre>

			<h3>Response</h3>
			<p>The response includes the message details with a <code>resources</code> field containing image URLs.</p>

			<h2>7. Additional Notes</h2>
			<ul>
				<li><strong>Image Prompts</strong>: The API may extract image prompts enclosed within specific tags in
					messages, such as <code>&lt;GenerateImage&gt;&lt;/GenerateImage&gt;</code>. Ensure your messages
					include these tags if image generation is desired.</li>
				<li><strong>RolePlayComponent</strong>: The <code>RolePlayComponent.java</code> handles the
					orchestration of conversations and image prompts.</li>
				<li><strong>ChatController</strong>: All chat-related endpoints and logic are defined in
					<code>ChatController.java</code>.
				</li>
				<li><strong>Generating Images for Characters</strong>: Images can also be generated for role characters
					outside of chat messages using the endpoints provided in <code>RoleCharacterController.java</code>.
				</li>
			</ul>

			<h2>Summary of the Flow</h2>
			<ol>
				<li><strong>Create a Role Character</strong>: Use <code>/v1/api/rolecharacter</code> to define your game
					character.</li>
				<li><strong>Create a Player</strong>: Set up a player profile with <code>/v1/api/player</code>.</li>
				<li><strong>Start a Chat Session</strong>: Initiate a chat using <code>/v1/api/chat</code> with both the
					player and character tokens.</li>
				<li><strong>Send Messages</strong>: Communicate within the chat using
					<code>/v1/api/chat/{chatToken}/message</code>.
				</li>
				<li><strong>Generate Images</strong>: If your message includes an image prompt, generate images using
					<code>/v1/api/chat/{chatToken}/message/{messageToken}/generateimage</code>.
				</li>
				<li><strong>Retrieve Chats and Messages</strong>: Use <code>/v1/api/chat/{chatToken}</code> to view the
					chat history.</li>
			</ol>
			<p>
				By following these steps, you can fully utilize the Solaris Role-Playing chat API to create immersive
				interactions between players and characters, including dynamic image generation.
			</p>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';

export default {

	setup() {

		const appUrl = ref(process.env.VUE_APP_APPLICATION_URL);
		return {
			appUrl,
		};
	},
};
</script>

<style scoped>
a {
	color: #3182ce;
	text-decoration: underline;
}

.bg-white {
	background-color: #ffffff;
}

.p-6 {
	padding: 1.5rem;
}

.rounded-lg {
	border-radius: 0.5rem;
}

.shadow-md {
	box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Typography styles */
.prose h1 {
	font-size: 2rem;
	margin-bottom: 1rem;
}

.prose h2 {
	font-size: 1.5rem;
	margin-top: 2rem;
	margin-bottom: 1rem;
}

.prose h3 {
	font-size: 1.25rem;
	margin-top: 1.5rem;
	margin-bottom: 0.75rem;
}

.prose p {
	margin-bottom: 1rem;
}

.prose ul,
.prose ol {
	margin-left: 1.5rem;
	margin-bottom: 1rem;
}

.prose li {
	margin-bottom: 0.5rem;
}

pre {
	background-color: #f5f5f5;
	padding: 1rem;
	overflow-x: auto;
	border-radius: 0.5rem;
	margin-bottom: 1rem;
}

code {
	background-color: #f5f5f5;
	padding: 0.2rem 0.4rem;
	border-radius: 0.3rem;
	font-size: small;
}

/* Syntax highlighting (simple example) */
.language-json {
	color: #2a9d8f;
}

.language-shell {
	color: #e76f51;
}
</style>