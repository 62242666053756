<template>
	<div v-if="isModalOpen" class="modal-overlay" @click="closeModal">
		<div class="modal-content" @click.stop>
			<img v-if="modalImageUrl" :src="modalImageUrl" alt="Zoomed Image" class="zoomed-image" />
			<div v-else class="no-image">No image available</div>
			<button class="close-button" @click="closeModal">Close</button>
		</div>
	</div>
</template>

<script>

export default {
	name: 'ImageModal',
	props: {
		modalImageUrl: {
			type: String,
			required: true
		},
		isModalOpen: {
			type: Boolean,
			required: true
		}
	},
	emits: ['close'],
	setup(_, { emit }) {
		const closeModal = () => {
			emit('close');
		};

		return {
			closeModal
		};
	}
};
</script>

<style scoped>
.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
}

.modal-content {
	position: relative;
	background: white;
	padding: 20px;
	border-radius: 8px;
	max-width: 90%;
	max-height: 90%;
	overflow: auto;
}

.zoomed-image {
	max-width: 100%;
	max-height: 80vh;
}

.close-button {
	position: absolute;
	top: 10px;
	right: 10px;
	background: red;
	color: white;
	border: none;
	padding: 5px 10px;
	cursor: pointer;
	border-radius: 4px;
}
</style>