<template>
	<div class="flex h-screen">
		<Sidebar v-show="user && user.email" />
		<div class="flex-1 p-6 bg-gray-100 overflow-auto bg-gradient-to-br from-indigo-400 to-cyan-400">
			<router-view  />
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Sidebar from './components/Sidebar.vue';

export default {
	components: {
		Sidebar,
	},
	data() {
		return {
			isLoggedId: false,
		};
	},
    computed: {
        ...mapGetters(['user'])
    },
	beforeMount() {
		/* fetch('/checksession')
			.then((response) => response.json())
			.then((data) => {
				if (!data.status) {
					this.$router.push('/login');
					this.setUser(null);
					this.isLoggedId = true
				}
			}); */
	},
	methods: {
        ...mapActions(['setUser', 'clearUser'])
    }
};
</script>
<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	color: #2c3e50;
}

nav {
	padding: 30px;
}

nav a {
	font-weight: bold;
	color: #2c3e50;
}

nav a.router-link-exact-active {
	color: #42b983;
}
</style>
