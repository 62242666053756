<template>
	<div class="mx-auto px-5 py-2">
		<h2 class="text-left text-white font-bold text-3xl pb-3 pl-2">User Management</h2>
		<div class="p-5 bg-gray-100/75 rounded-lg shadow-md">
			<div class="tabs mb-0">
				<button :class="{ 'active': activeTab === 'list' }" @click="activeTab = 'list'">List Users</button>
				<button :class="{ 'active': activeTab === 'create' }" @click="activeTab = 'create'" v-if="$store.state.user.userId===1">Create User</button>
			</div>

			<!-- List Users Tab -->
			<div v-if="activeTab === 'list'" class="table-auto bg-white p-5">
				<table class="min-w-full">
					<thead>
						<tr>
							<th class="py-2">ID</th>
							<th class="py-2">Name</th>
							<th class="py-2">Email</th>
							<th class="py-2">Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="user in users" :key="user.userId">
							<td class="py-2">{{ user.userId }}</td>
							<td class="py-2">{{ user.fullname }}</td>
							<td class="py-2">{{ user.email }}</td>
							<td class="py-2 space-x-2 flex items-center justify-center">
								<button @click="editUser(user)" class="btn btn-primary inline-flex items-center gap-1">
									<UserPen class="w-4 h-4" />
									Edit
								</button>
								<button @click="deleteUser(user.userId)"
									class="btn btn-danger inline-flex items-center gap-1"
									v-if="$store.state.user.userId===1">
									<UserMinus class="w-4 h-4" />
									Delete
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- Create/Edit User Tab -->
			<div v-if="activeTab === 'create'" class="bg-white p-5">
				<form @submit.prevent="handleSubmit" class="space-y-4">
					<div class="flex flex-col gap-2">
						<label for="fullname" class="text-sm font-medium text-gray-700">Full Name</label>
						<input v-model="userForm.fullname" type="text" id="fullname" required
							class="mt-1 block w-full p-2 border rounded-md" placeholder="Enter full name" />
					</div>
					<div class="flex flex-col gap-2">
						<label for="email" class="text-sm font-medium text-gray-700">Email</label>
						<input v-model="userForm.email" type="email" id="email" required
							class="mt-1 block w-full p-2 border rounded-md" placeholder="Enter email" />
					</div>

					<div class="flex flex-col gap-2">
						<label for="password" class="text-sm font-medium text-gray-700">Password</label>
						<input v-model="userForm.password" type="password" id="password" required
							class="mt-1 block w-full p-2 border rounded-md" placeholder="Enter password" />
					</div>
					<div class="flex flex-col gap-2" v-if="$store.state.user.userId===1">
						<label for="isActive" class="text-sm font-medium text-gray-700">Active</label>
						<select v-model="userForm.isActive" id="isActive" required
							class="mt-1 block w-full p-2 border rounded-md">
							<option :value="true">Active</option>
							<option :value="false">Inactive</option>
						</select>
					</div>

					<button type="submit" class="btn btn-primary inline-flex items-center gap-1">
						<UserPlus class="w-4 h-8" />
						{{ editMode ? 'Update User' : 'Create User' }}
					</button>

					<!-- ApiAuth Section -->
					<div v-if="editMode" class="mt-8 border-t pt-4">
						<h3 class="text-lg font-medium mb-4">API Authentication Keys</h3>

						<!-- ApiAuth List -->
						<div class="overflow-x-auto">
							<table class="min-w-full mb-4">
								<thead>
									<tr>
										<th class="py-2">Name</th>
										<th class="py-2">Date Created</th>
										<th class="py-2">Actions</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="auth in apiAuths" :key="auth.apiAuthId">
										<td class="py-2">{{ auth.name }}</td>
										<td class="py-2">{{ new Date(auth.dateCreated).toLocaleString() }}</td>
										<td class="py-2">
											<button @click.prevent="deleteApiAuth(auth.apiAuthId)"
												class="btn btn-danger inline-flex items-center gap-1">
												<UserMinus class="w-4 h-4" />
												Delete
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<!-- New ApiAuth Form -->
						<form @submit.prevent="createNewApiAuth" class="space-y-4">
							<div class="flex flex-col gap-2">
								<label for="apiKeyName" class="text-sm font-medium text-gray-700">API Key Name</label>
								<input v-model="apiKeyForm.name" type="text" id="apiKeyName" required
									class="mt-1 block w-full p-2 border rounded-md" placeholder="Enter API key name" />
							</div>
							<div class="flex flex-col gap-2">
								<label for="apiSecret" class="text-sm font-medium text-gray-700">API Secret</label>
								<div class="flex items-center gap-2">
									<input v-model="apiKeyForm.apiSecret" type="text" id="apiSecret" required
										class="mt-1 block w-full p-2 border rounded-md"
										placeholder="Enter API secret" />
									<button type="button" @click="generateApiSecret()"
										class="btn btn-primary inline-flex items-center gap-1">
										<Dices class="w-4 h-6" />
									</button>
								</div>
							</div>

							<button type="submit" class="btn btn-primary inline-flex items-center gap-1">
								<UserPlus class="w-4 h-8" />
								Generate New API Key
							</button>
						</form>

					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { UserPen, UserMinus, UserPlus, Dices } from 'lucide-vue-next';

export default {
	components: {
		UserPen,
		UserMinus,
		UserPlus,
		Dices
	},
	setup() {
		const route = useRoute();
		const router = useRouter();
		const activeTab = ref('list');
		const users = ref([]);
		const editMode = ref(false);
		const toast = useToast();
		const userForm = ref({
			userId: null,
			fullname: '',
			email: ''
		});
		const apiAuths = ref([]);
		const apiKeyForm = ref({
			name: '',
			apiSecret: ''
		});

		const fetchUsers = async () => {
			try {
				const response = await fetch('/usermanagement');
				if (response.ok) {
					const data = await response.json();
					users.value = data;

					// Run the watch logic after fetching users
					if (route.params.id) {
						const newId = route.params.id;
						console.log("newId:", newId, "parsed newId:", parseInt(newId));
						if (users.value.length > 0) {
							users.value.forEach(u => console.log("userId:", u.userId, "type:", typeof u.userId));
						} else {
							console.log("users.value is empty");
						}

						const user = users.value.find(u => u.userId === parseInt(newId));
						console.log(user, newId, users);
						if (user) {
							await editUser(user);
						}
					}
				} else {
					toast.error('Failed to fetch users');
				}
			} catch (error) {
				toast.error('Error fetching users');
			}
		};

		const handleSubmit = async () => {
			try {
				const url = editMode.value ? `/usermanagement/${userForm.value.userId}` : '/usermanagement';
				const method = editMode.value ? 'PUT' : 'POST';

				const response = await fetch(url, {
					method,
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify(userForm.value)
				});

				if (response.ok) {
					toast.success(`User ${editMode.value ? 'updated' : 'created'} successfully`);
					await fetchUsers();
					resetForm();
					activeTab.value = 'list';
				} else {
					toast.error(`Failed to ${editMode.value ? 'update' : 'create'} user`);
				}
			} catch (error) {
				toast.error(`Error ${editMode.value ? 'updating' : 'creating'} user`);
			}
		};

		const editUser = async (user) => {
			userForm.value = { ...user };
			editMode.value = true;
			activeTab.value = 'create';
			await fetchApiAuths(user.userId);

			// Update URL without triggering route watcher
			router.push({
				name: 'UserEdit',
				params: { id: user.userId }
			});
		};

		const cancelEdit = () => {
			resetForm();
			router.push({ name: 'UserManagement' });
		};

		const deleteUser = async (id) => {
			if (confirm('Are you sure you want to delete this user?')) {
				try {
					const response = await fetch(`/usermanagement/${id}`, {
						method: 'DELETE'
					});

					if (response.ok) {
						toast.success('User deleted successfully');
						await fetchUsers();
					} else {
						toast.error('Failed to delete user');
					}
				} catch (error) {
					toast.error('Error deleting user');
				}
			}
		};

		const fetchApiAuths = async (userId) => {
			try {
				const response = await fetch(`/usermanagement/${userId}/apiauth`);
				if (response.ok) {
					const data = await response.json();
					apiAuths.value = data;
				} else {
					toast.error('Failed to fetch API keys');
				}
			} catch (error) {
				toast.error('Error fetching API keys');
			}
		};

		const createNewApiAuth = async () => {
			try {
				const response = await fetch(`/usermanagement/${userForm.value.userId}/apiauth`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						name: apiKeyForm.value.name,
						apiSecret: apiKeyForm.value.apiSecret
					})
				});

				if (response.ok) {
					toast.success('New API key generated');
					await fetchApiAuths(userForm.value.userId);
					apiKeyForm.value.name = ''; // Reset form
				} else {
					toast.error('Failed to generate API key');
				}
			} catch (error) {
				toast.error('Error generating API key');
			}
		};

		const deleteApiAuth = async (apiAuthId) => {
			if (confirm('Are you sure you want to delete this API key?')) {
				try {
					const response = await fetch(
						`/usermanagement/${userForm.value.userId}/apiauth/${apiAuthId}`,
						{ method: 'DELETE' }
					);

					if (response.ok) {
						toast.success('API key deleted');
						await fetchApiAuths(userForm.value.userId);
					} else {
						toast.error('Failed to delete API key');
					}
				} catch (error) {
					toast.error('Error deleting API key');
				}
			}
		};

		const generateApiSecret = () => {
			// Generate 32 random bytes
			const randomBytes = new Uint8Array(32);
			window.crypto.getRandomValues(randomBytes);

			// Convert to base64 and clean up
			const base64String = btoa(String.fromCharCode.apply(null, randomBytes))
				.replace(/\+/g, '-')
				.replace(/\//g, '_')
				.replace(/=/g, '');

			apiKeyForm.value.apiSecret = base64String;
		};

		const resetForm = () => {
			userForm.value = {
				userId: null,
				fullname: '',
				email: ''
			};
			editMode.value = false;
		};

		onMounted(fetchUsers);

		return {
			activeTab,
			users,
			userForm,
			editMode,
			handleSubmit,
			editUser,
			deleteUser,
			apiAuths,
			createNewApiAuth,
			deleteApiAuth,
			apiKeyForm,
			cancelEdit,
			generateApiSecret
		};
	}
};
</script>

<style scoped>
.tabs {
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
}

.tabs button {
	padding: 0.5rem 1rem;
	border: none;
	cursor: pointer;
	border-radius: 0.375rem;
}

.tabs button.active {
	background-color: #4f46e5;
	color: white;
}

.btn {
	padding: 0.5rem 1rem;
	border-radius: 0.375rem;
	font-weight: 500;
	cursor: pointer;
	transition: background-color 0.2s;
}

.btn-primary {
	background-color: #4f46e5;
	color: white;
}

.btn-danger {
	background-color: #dc2626;
	color: white;
}

.btn-primary:hover {
	background-color: #4338ca;
}

.btn-danger:hover {
	background-color: #b91c1c;
}

.btn:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
</style>