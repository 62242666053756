<template>
	<div class="min-h-screen flex items-center justify-center text-white">
		<div class="login-box p-8 rounded-lg shadow-md w-full max-w-md">
			<img src="/img/logo.webp" alt="Company Logo" class="mx-auto mb-6 w-64 h-64">
			<h1 class="text-2xl font-bold mb-6 text-center bg-gradient-to-r from-teal-400 to-teal-800 bg-clip-text text-transparent ">SOLARIS</h1>
			
			<form @submit.prevent="initLogin">
				<div class="mb-4">
					<label for="email" class="block text-teal-100 text-left">Email</label>
					<input type="email" v-model="email" id="email" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-teal-900" required>
				</div>
				<div class="mb-4">
					<label for="password" class="block text-teal-100 text-left">Password</label>
					<input type="password" v-model="password" id="password" class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-teal-900" required>
				</div>
				<!-- <div class="mb-4 flex items-center">
					<input type="checkbox" v-model="rememberMe" id="rememberMe" class="h-4 w-4 text-teal-100 focus:ring-indigo-500 border-gray-300 rounded">
					<label for="rememberMe" class="ml-2 block text-teal-100">Remember me</label>
				</div> -->
				<button type="submit" class="w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Login</button>
			</form>
		</div>
	</div>
</template>

<script>
import { mapActions } from 'vuex';
import { useToast } from 'vue-toastification';

export default {
	data() {
		return {
			email: '',
			password: '',
			rememberMe: false
		};
	},
	beforeMount() {
		if (this.$store.state.user) {
			this.$router.push('/dashboard');
		}
	},
	methods: {
		...mapActions(['setUser']),
		async initLogin() {
			const toast = useToast();
			try {
				const response = await fetch('/login', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({
						email: this.email,
						password: this.password
					})
				});
				const data = await response.json();
				if (data.status) {
					// set parent component isLoggedIn to true
					
					this.$router.push('/dashboard');

				} else {
					toast.error('Invalid email or password');
				}
			} catch (error) {
				toast.error('Invalid email or password');
			}
		}
	}
};
</script>

<style scoped>
.login-box {
	background-color: #272c32;
}
</style>
