<template>
	<div class="container mx-auto p-4">
		<h2 class="text-left text-white font-bold text-3xl pb-3 pl-2">Assistant</h2>
		<div class="p-5 bg-gray-100/75 rounded-lg shadow-md">
			<!-- Tabs -->
			<div class="tabs mb-0">
				<button :class="{ 'active': activeTab === 'list' }" @click="activeTab = 'list'">List Assistants</button>
				<button :class="{ 'active': activeTab === 'create' }" @click="activeTab = 'create'">Create
					Assistant</button>
			</div>

			<!-- List Tab -->
			<div v-if="activeTab === 'list'" class="table-auto bg-white p-5">
				<table class="min-w-full">
					<thead>
						<tr class="text-left">
							<th class="py-2">Name</th>
							<th class="py-2">Description</th>
							<th class="py-2">Provider</th>
							<th class="py-2">Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="assistant in assistants" :key="assistant.token">
							<td class="py-2">{{ assistant.name }}</td>
							<td class="py-2">{{ assistant.description }}</td>
							<td class="py-2">{{ assistant.provider.name }}</td>
							<td class="py-2">
								<button @click="openChatTab(assistant)" class="btn btn-primary text-xs px-0 py-1">
									<MessageSquareText size="16" />
								</button>
								<button @click="editAssistant(assistant)" class="btn btn-primary text-xs px-0 py-1">
									<Edit3 size="16" />
								</button>
								<button @click="deleteAssistant(assistant.token)"
									class="btn btn-danger text-xs px-0 py-1 bg-red-500 hover:bg-red-700">
									<XCircle size="16" />
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- Create Tab -->
			<div v-if="activeTab === 'create'">
				<form @submit.prevent="saveAssistant" class="space-y-4">
					<div class="bg-gradient-to-tr from-slate-100 to-gray-200 p-4 rounded-lg">
						<div class="mb-4">
							<label for="name" class="block text-sm font-medium text-gray-700">Name</label>
							<input v-model="form.name" id="name" type="text" required
								class="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
						</div>
						<div class="mb-4">
							<label for="description" class="block text-sm font-medium text-gray-700">Description</label>
							<input v-model="form.description" id="description" type="text"
								class="mt-1 block w-full p-2 border border-gray-300 rounded-md" />
						</div>
						<div class="mb-4">
							<label for="instruction" class="block text-sm font-medium text-gray-700">Instruction</label>
							<textarea v-model="form.instruction" id="instruction" rows="4"
								class="mt-1 block w-full p-2 border border-gray-300 rounded-md"></textarea>
						</div>
						<div class="mb-4">
							<label for="provider" class="block text-sm font-medium text-gray-700">Provider</label>
							<select v-model="form.provider" id="provider" required class="mt-1 block w-full p-2 border border-gray-300 rounded-md">
								<option v-for="provider in providers" :key="provider.id" :value="provider">
									{{ provider.name }}
								</option>
							</select>
						</div>
					</div>
					<button type="submit" class="btn btn-primary">Save Assistant</button>
				</form>
			</div>

			<!-- Chat Tab (if needed) -->
			<div v-if="activeTab === 'chat'">
				<!-- Include your AssistantChat component here -->
				<AssistantChat :assistant="selectedAssistant" />
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue';
//import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import AssistantChat from './AssistantChat.vue';
import { Edit3, XCircle, MessageSquareText } from 'lucide-vue-next';

export default {
	components: {
		AssistantChat,
		Edit3,
		XCircle,
		MessageSquareText
	},
	setup() {
		//const router = useRouter();
		const store = useStore();
		const accessToken = store.state.user.accessToken;
		const toast = useToast();

		const activeTab = ref('list');
		const assistants = ref([]);
		const providers = ref([]);
		const form = ref({
			token: '',
			name: '',
			description: '',
			instruction: '',
		});
		const selectedAssistant = ref(null);

		const fetchAssistants = async () => {
			try {
				const response = await fetch('/v1/api/assistant', {
					headers: {
						Authorization: `Bearer ${accessToken}`,
					},
				});
				if (response.ok) {
					assistants.value = await response.json();
				} else {
					toast.error('Failed to fetch assistants');
				}
			} catch (error) {
				toast.error('Error fetching assistants');
			}
		};

		const saveAssistant = async () => {
			try {
				const url = form.value.token
					? `/v1/api/assistant/${form.value.token}`
					: '/v1/api/assistant';
				const method = form.value.token ? 'PUT' : 'POST';
				const response = await fetch(url, {
					method,
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
					},
					body: JSON.stringify(form.value),
				});
				if (response.ok) {
					await fetchAssistants();
					toast.success('Assistant saved successfully');
					activeTab.value = 'list';
					form.value = { token: '', name: '', description: '', instruction: '' };
				} else {
					toast.error('Failed to save assistant');
				}
			} catch (error) {
				toast.error('Error saving assistant');
			}
		};

		const fetchProviders = async () => {
			const response = await fetch('/providermanagement');
			const data = await response.json();
			providers.value = data.content;
		};

		const editAssistant = (assistant) => {
			form.value = { ...assistant };
			activeTab.value = 'create';
		};

		const deleteAssistant = async (token) => {
			if (confirm('Are you sure you want to delete this assistant?')) {
				try {
					const response = await fetch(`/v1/api/assistant/${token}`, {
						method: 'DELETE',
						headers: {
							Authorization: `Bearer ${accessToken}`,
						},
					});
					if (response.ok) {
						toast.success('Assistant deleted successfully');
						await fetchAssistants();
					} else {
						toast.error('Failed to delete assistant');
					}
				} catch (error) {
					toast.error('Error deleting assistant');
				}
			}
		};

		const openChatTab = (assistant) => {
			selectedAssistant.value = assistant;
			activeTab.value = 'chat';
		};

		onMounted(() => {
			fetchAssistants();
			fetchProviders();
		});

		return {
			activeTab,
			assistants,
			form,
			selectedAssistant,
			saveAssistant,
			editAssistant,
			deleteAssistant,
			openChatTab,
			fetchProviders,
			providers
		};
	},
};
</script>

<style scoped>
.tabs {
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
}

.tabs button {
	padding: 0.5rem 1rem;
	border: none;
	cursor: pointer;
}

.tabs button.active {
	background-color: #4f46e5;
	color: white;
}

.btn {
	padding: 0.5rem 1rem;
	background-color: #4f46e5;
	color: white;
	border: none;
	cursor: pointer;
}

.btn-primary {
	background-color: #4f46e5;
}

.btn-primary:hover {
	background-color: #4338ca;
}

.btn-danger {
	background-color: #dc2626;
	color: white;
}
</style>