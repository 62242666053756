<template>
	<div class="mx-auto p-5">
		<h2 class="text-left text-white font-bold text-3xl pb-3">Players</h2>
		<div class="p-5 bg-gray-100/75 rounded-lg shadow-md">
			<div class="tabs mb-5 flex space-x-4">
				<button :class="activeTab === 'list' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'"
					class="px-4 py-2 rounded" @click="activeTab = 'list'">
					List Players
				</button>
				<button :class="activeTab === 'create' ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700'"
					class="px-4 py-2 rounded" @click="activeTab = 'create'">
					Create Player
				</button>
			</div>

			<div v-if="activeTab === 'list'" class="overflow-x-auto">
				<table class="min-w-full bg-white">
					<thead>
						<tr>
							<th class="py-2 px-4 border-b">Token</th>
							<th class="py-2 px-4 border-b">userName</th>
							<th class="py-2 px-4 border-b">Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="player in players" :key="player.token" class="hover:bg-gray-50">
							<td class="py-2 px-4 border-b">
								<input v-model="player.token" readonly
									class="p-1 border border-gray-300 rounded bg-gray-200" />
							</td>
							<td class="py-2 px-4 border-b">
								<input v-model="player.userName" class="p-1 border border-gray-300 rounded" />
							</td>
							<td class="py-2 px-4 border-b flex space-x-2">
								<button @click="updatePlayer(player)" class="text-green-500 hover:text-green-700">
									<CheckCircle size="16" />
								</button>
								<button @click="deletePlayer(player.token)" class="text-red-500 hover:text-red-700">
									<XCircle size="16" />
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div v-if="activeTab === 'create'">
				<form @submit.prevent="addPlayer" class="space-y-4">
					<div class="bg-gradient-to-tr from-slate-100 to-gray-200 p-4 rounded-lg">
						<div class="mb-4">
							<label for="userName" class="block text-sm font-medium text-gray-700">userName</label>
							<input type="text" id="userName" v-model="newPlayer.userName"
								class="mt-1 block w-full p-2 border border-gray-300 rounded" required />
						</div>
					</div>
					<button type="submit"
						class="btn btn-primary bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded"
						:disabled="!newPlayer.userName">
						Add Player
					</button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { CheckCircle, XCircle } from 'lucide-vue-next';

export default {
	components: {
		CheckCircle,
		XCircle
	},
	setup() {
		const store = useStore();
		const accessToken = store.state.user.accessToken;
		const activeTab = ref('list');
		const players = ref([]);
		const newPlayer = ref({
			userName: ''
		});
		const toast = useToast();

		const fetchPlayers = async () => {
			try {
				const response = await fetch('/v1/api/player', {
					headers: {
						'Authorization': `Bearer ${accessToken}`
					}
				});
				const data = await response.json();
				players.value = data;
			} catch (error) {
				toast.error('Failed to fetch players');
				console.error(error);
			}
		};

		const addPlayer = async () => {
			try {
				const response = await fetch('/v1/api/player', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${accessToken}`
					},
					body: JSON.stringify(newPlayer.value)
				});
				const data = await response.json();
				if (response.ok) {
					toast.success('Player added successfully');
					players.value.push(data);
					newPlayer.value.userName = '';
					activeTab.value = 'list';
				} else {
					toast.error('Failed to add player');
				}
			} catch (error) {
				toast.error('Failed to add player');
				console.error(error);
			}
		};

		const deletePlayer = async (token) => {
			if (confirm('Are you sure you want to delete this player?')) {
				try {
					const response = await fetch(`/v1/api/player/${token}`, {
						method: 'DELETE',
						headers: {
							'Authorization': `Bearer ${accessToken}`
						}
					});
					await response.text();
					if (response.status === 204) {
						toast.success('Player deleted successfully');
						players.value = players.value.filter(player => player.token !== token);
					} else {
						toast.error('Failed to delete player');
					}
				} catch (error) {
					toast.error('Failed to delete player');
					console.error(error);
				}
			}
		};

		const updatePlayer = async (player) => {
			try {
				const response = await fetch(`/v1/api/player/${player.token}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${accessToken}`
					},
					body: JSON.stringify(player)
				});
				await response.json();
				if (response.ok) {
					toast.success('Player updated successfully');
					fetchPlayers();
				} else {
					toast.error('Failed to update player');
				}
			} catch (error) {
				toast.error('Failed to update player');
				console.error(error);
			}
		};

		onMounted(() => {
			fetchPlayers();
		});

		return {
			activeTab,
			players,
			newPlayer,
			fetchPlayers,
			addPlayer,
			deletePlayer,
			updatePlayer
		};
	}
};
</script>

<style scoped>
.tabs button {
	transition: background-color 0.3s;
}

.btn {
	display: inline-block;
}

.btn-primary:disabled {
	opacity: 0.6;
	cursor: not-allowed;
}
</style>