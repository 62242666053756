<template>
	<div class="mx-auto px-5 py-2">
		<h2 class="text-left text-white font-bold text-3xl pb-3 pl-2">Character Management</h2>
		<div class="p-5 bg-gray-100/75 rounded-lg shadow-md">
			<div class="tabs mb-0">
				<button :class="{ 'active': activeTab === 'list' }" @click="activeTab = 'list'">List Characters</button>
				<button :class="{ 'active': activeTab === 'create' }" @click="activeTab = 'create'">Create
					Character</button>
			</div>

			<!-- List Characters Tab -->
			<div v-if="activeTab === 'list'" class="table-auto bg-white p-5">
				<table class="min-w-full">
					<thead>
						<tr>
							<!-- <th class="py-2 px-4 border-b">Token</th> -->
							<th class="py-2 px-4 border-b">Name</th>
							<!-- <th class="py-2 px-4 border-b">Brief</th>
							<th class="py-2 px-4 border-b">Gender</th> -->
							<th class="py-2 px-4 border-b">Tags</th>
							<th class="py-2 px-4 border-b">Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="character in characters" :key="character.token">
							<!-- <td class="py-2 px-4 border-b">{{ character.token }}</td> -->
							<td class="py-2 px-4 border-b">{{ character.name }}</td>
							<!-- <td class="py-2 px-4 border-b">{{ character.brief.substring(0, 50) }}...</td>
							<td class="py-2 px-4 border-b">{{ character.gender }}</td> -->
							<td class="py-2 px-4 border-b text-sm">{{ character.tags }}</td>
							<td class="py-2 px-4 border-b space-x-2 flex items-center justify-center">
								<button @click="editCharacter(character)"
									class="btn btn-primary inline-flex items-center gap-1">
									<UserPen class="w-4 h-4" />
									Edit
								</button>
								<button @click="deleteCharacter(character.token)"
									class="btn btn-danger inline-flex items-center gap-1">
									<UserMinus class="w-4 h-4" />
									Delete
								</button>
								<!-- <button @click="goToImages(character.token)"
									class="btn btn-primary inline-flex items-center gap-1">
									<UserPlus class="w-4 h-4" />
									Images
								</button>
								<button @click="goToImageGeneration(character.token)"
									class="btn btn-primary inline-flex items-center gap-1">
									<UserPlus class="w-4 h-4" />
									Generate Image
								</button> -->
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- Create/Edit Character Tab -->
			<div v-if="activeTab === 'create'" class="bg-white p-5">
				<form @submit.prevent="handleSubmit" class="space-y-4">
					<div v-for="field in fields" :key="field.key" class="flex flex-col gap-2">
						<label :for="field.key" class="text-sm font-medium text-gray-700">{{ field.label }}</label>

						<input v-if="field.type === 'text'" :type="field.type" :id="field.key"
							v-model="characterForm[field.key]" :placeholder="field.placeholder"
							class="mt-1 block w-full p-2 border rounded-md" required />

						<textarea v-else-if="field.type === 'textarea'" :id="field.key"
							v-model="characterForm[field.key]" :placeholder="field.placeholder" rows="4"
							class="mt-1 block w-full p-2 border rounded-md" required></textarea>

						<select v-else-if="field.type === 'select'" :id="field.key" v-model="characterForm[field.key]"
							class="mt-1 block w-full p-2 border rounded-md" required>
							<option v-for="option in field.options" :key="option" :value="option">
								{{ option }}
							</option>
						</select>
					</div>

					<button type="submit" class="btn btn-primary inline-flex items-center gap-1">
						<UserPlus class="w-4 h-4" />
						{{ editMode ? 'Update Character' : 'Create Character' }}
					</button>
				</form>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import { ref, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { UserPen, UserMinus, UserPlus } from 'lucide-vue-next';
import { useStore } from 'vuex';
		
export default {
	components: {
		UserPen,
		UserMinus,
		UserPlus
	},
	setup() {
		const route = useRoute();
		const router = useRouter();
		const activeTab = ref('list');
		const characters = ref([]);
		const editMode = ref(false);
		const toast = useToast();
		const store = useStore();

		const characterForm = ref({
			token: '',
			name: '',
			brief: '',
			tags: '',
			imageGenerationModelId: ''
		});

		const fields = [
			{ key: 'name', label: 'Name', type: 'text', placeholder: 'Enter character name' },
			{ key: 'brief', label: 'Description', type: 'textarea', placeholder: 'Enter character description' },
			{ key: 'tags', label: 'Tags', type: 'text', placeholder: 'Enter tags (comma separated)' }
		];

		const accessToken = store.state.user.accessToken;

		const fetchCharacters = async () => {
			try {
				const response = await fetch('/v1/api/rolecharacter', {
					headers: {
						'Authorization': `Bearer ${accessToken}`
					}
				});
				if (response.ok) {
					const data = await response.json();
					characters.value = data;
				} else {
					toast.error('Failed to fetch characters');
				}
			} catch (error) {
				toast.error('Error fetching characters');
			}
		};

		const handleSubmit = async () => {
			try {
				const url = '/v1/api/rolecharacter';
				const method = editMode.value ? 'PUT' : 'POST';

				const response = await fetch(url, {
					method,
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${accessToken}`
					},
					body: JSON.stringify(characterForm.value)
				});

				if (response.ok) {
					toast.success(`Character ${editMode.value ? 'updated' : 'created'} successfully`);
					await fetchCharacters();
					resetForm();
					activeTab.value = 'list';
				} else {
					toast.error(`Failed to ${editMode.value ? 'update' : 'create'} character`);
				}
			} catch (error) {
				toast.error(`Error ${editMode.value ? 'updating' : 'creating'} character`);
			}
		};

		const editCharacter = async (character) => {
			characterForm.value = { ...character };
			editMode.value = true;
			activeTab.value = 'create';
			router.push({
				name: 'CharacterEdit',
				params: { token: character.token }
			});
		};

		const deleteCharacter = async (token) => {
			if (confirm('Are you sure you want to delete this character?')) {
				try {
					const response = await fetch(`/v1/api/rolecharacter/${token}`, {
						method: 'DELETE',
						headers: {
							'Authorization': `Bearer ${accessToken}`
						}
					});

					if (response.ok) {
						toast.success('Character deleted successfully');
						await fetchCharacters();
					} else {
						toast.error('Failed to delete character');
					}
				} catch (error) {
					toast.error('Error deleting character');
				}
			}
		};

		const goToImages = (token) => {
			router.push({
				name: 'CharacterImages',
				params: { token }
			});
		};

		const goToImageGeneration = (token) => {
			router.push({
				name: 'ImageGenerationModel',
				params: { token }
			});
		};

		const resetForm = () => {
			characterForm.value = {
				token: '',
				name: '',
				brief: '',
				gender: '',
				tags: '',
				status: 'active'
			};
			editMode.value = false;
		};

		onMounted(fetchCharacters);

		return {
			activeTab,
			characters,
			characterForm,
			editMode,
			fields,
			handleSubmit,
			editCharacter,
			deleteCharacter,
			goToImages,
			goToImageGeneration
		};
	}
};
</script>

<style>
.tabs {
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
}

.tabs button {
	padding: 0.5rem 1rem;
	border: none;
	cursor: pointer;
	border-radius: 0.375rem;
}

.tabs button.active {
	background-color: #4f46e5;
	color: white;
}

.btn {
	padding: 0.5rem 1rem;
	border-radius: 0.375rem;
	font-weight: 500;
	cursor: pointer;
	transition: background-color 0.2s;
}

.btn-primary {
	background-color: #4f46e5;
	color: white;
}

.btn-danger {
	background-color: #dc2626;
	color: white;
}

.btn-primary:hover {
	background-color: #4338ca;
}

.btn-danger:hover {
	background-color: #b91c1c;
}

.btn:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
</style>