<template>
	<div class="container mx-auto px-4">
		<!-- Header Section -->
		<header class="mb-8">
			<h1 class="text-3xl font-bold text-white">Dashboard</h1>
			<p class="text-white">Welcome to the Dashboard!</p>
		</header>

		<!-- Statistics Section -->
		<section class="mb-8">
			<div class="grid md:grid-cols-4 gap-4">
				<div class="bg-blue-500 text-white rounded-lg p-4">
					<h3 class="text-lg font-semibold">Players</h3>
					<p class="text-2xl">{{ stats.players }}</p>
				</div>
				<div class="bg-green-500 text-white rounded-lg p-4">
					<h3 class="text-lg font-semibold">Characters</h3>
					<p class="text-2xl">{{ stats.characters }}</p>
				</div>
				<div class="bg-purple-500 text-white rounded-lg p-4">
					<h3 class="text-lg font-semibold">Chats</h3>
					<p class="text-2xl">{{ stats.chats }}</p>
				</div>
				<div class="bg-yellow-500 text-white rounded-lg p-4">
					<h3 class="text-lg font-semibold">Messages</h3>
					<p class="text-2xl">{{ stats.chatMessages }}</p>
				</div>
			</div>
		</section>

		<!-- Actions Section -->
		<section class="grid md:grid-cols-2 gap-6">
			<div class="bg-white rounded-lg shadow-lg p-6">
				<h2 class="text-2xl font-semibold text-gray-800 mb-4">RoleCharacter</h2>
				<div class="space-x-4">
					<button
						class="bg-indigo-600 hover:bg-indigo-700 text-white font-medium px-4 py-2 rounded-md transition duration-150"
						@click="$router.push('/character')">
						Create
					</button>
					<button
						class="bg-cyan-600 hover:bg-cyan-700 text-white font-medium px-4 py-2 rounded-md transition duration-150"
						@click="$router.push('/character')">
						List
					</button>
				</div>
			</div>

			<div class="bg-white rounded-lg shadow-lg p-6">
				<h2 class="text-2xl font-semibold text-gray-800 mb-4">Chat</h2>
				<button
					class="bg-indigo-600 hover:bg-indigo-700 text-white font-medium px-4 py-2 rounded-md transition duration-150"
					@click="$router.push('/chat')">
					List
				</button>
			</div>
		</section>
	</div>
</template>
<script>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
	setup() {
		const store = useStore();
		const accessToken = store.state.user.accessToken;
		const stats = ref({
			players: 0,
			characters: 0,
			chats: 0,
			chatMessages: 0
		});

		const fetchStats = async () => {
			try {
				// Fetch characters count
				const characterResponse = await fetch('/v1/api/rolecharacter', {
					headers: {
						'Authorization': `Bearer ${accessToken}`
					}
				});
				const characterData = await characterResponse.json();
				if (characterData) {
					stats.value.characters = characterData.length;
				}

				// Fetch chats count
				const chatResponse = await fetch('/v1/api/chat', {
					headers: {
						'Authorization': `Bearer ${accessToken}`
					}
				});
				const chatData = await chatResponse.json();
				if (chatData) {
					stats.value.chats = chatData.length;
					// Calculate total messages from all chats
					stats.value.chatMessages = chatData.reduce((total, chat) =>
						total + (chat.chatMessages?.length || 0), 0);
					// Fetch messages for each chat
					for (const chat of chatData) {
						if (chat.token) {
							try {
								const messagesResponse = await fetch(`/v1/api/chat/${chat.token}`, {
									headers: {
										'Authorization': `Bearer ${accessToken}`
									}
								});
								const messagesData = await messagesResponse.json();
								if (messagesData.status && messagesData.chatMessages) {
									stats.value.chatMessages += messagesData.chatMessages.length;
								}
							} catch (error) {
								console.error(`Failed to fetch messages for chat ${chat.token}:`, error);
							}
						}
					}
				}

				// Fetch players count
				const playerResponse = await fetch('/v1/api/player', {
					headers: {
						'Authorization': `Bearer ${accessToken}`
					}
				});
				const playerData = await playerResponse.json();
				if (playerData) {
					stats.value.players = playerData.length;
				}
			} catch (error) {
				console.error('Failed to fetch dashboard stats:', error);
			}
		};

		onMounted(() => {
			fetchStats();
		});

		return {
			stats
		}
	}
};
</script>
