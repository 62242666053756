<!-- create a responsive sidebar component. it must be a small square button with an icon for to make it expand full  vview for mobile devices. for large devices side bar must be always visible but can be condensed with  button click. when condensed only buttons will be visible. there are mane example out there about this concept. i suppose you already understand what i asked. -->
<template>
	<div :class="['flex flex-col bg-gray-800 text-white', isCollapsed ? 'w-20' : 'w-64']" class="h-screen transition-width duration-300">
		<div class="flex items-center justify-between p-4 bg-gray-900">
			<h1 class="text-lg font-semibold flex items-center">
				<img src="/img/logo.webp" alt="Solaris Logo" class="h-8 w-8 mr-2" v-if="!isCollapsed">
				<span v-if="!isCollapsed">SOLARIS</span>
			</h1>
			<button @click="toggleSidebar" class="focus:outline-none">
				<span v-if="isCollapsed" class="text-xl">›</span>
				<span v-else class="text-xl">‹</span>
			</button>
		</div>
		<nav class="flex-grow">
			<ul>
				<li class="p-2 hover:bg-gray-700 flex items-center">
					<router-link to="/dashboard" class="block w-full text-left text-white">
						<Home class="mr-2" v-if="isCollapsed" />
						<span v-if="!isCollapsed">Dashboard</span>
					</router-link>
				</li>
				<li class="p-2 hover:bg-gray-700 flex items-center">
					<router-link to="/character" class="block w-full text-left text-white">
						<User class="mr-2" v-if="isCollapsed" />
						<span v-if="!isCollapsed">Character</span>
					</router-link>
				</li>
				<li class="p-2 hover:bg-gray-700 flex items-center">
					<router-link to="/player" class="block w-full text-left text-white">
						<Users class="mr-2" v-if="isCollapsed" />
						<span v-if="!isCollapsed">Player</span>
					</router-link>
				</li>
				<li class="p-2 hover:bg-gray-700 flex items-center">
					<router-link to="/chat" class="block w-full text-left text-white">
						<MessageCircle class="mr-2" v-if="isCollapsed" />
						<span v-if="!isCollapsed">Chat</span>
					</router-link>
				</li>
				<li class="p-2 hover:bg-gray-700 flex items-center">
					<router-link to="/imagegenerationmodel" class="block w-full text-left text-white">
						<Image class="mr-2" v-if="isCollapsed" />
						<span v-if="!isCollapsed">Image Generation Model</span>
					</router-link>
				</li>
				<li class="p-2 hover:bg-gray-700 flex items-center" v-if="store.state.user.userId === 1">
					<router-link to="/provider" class="block w-full text-left text-white">
						<Settings class="mr-2" v-if="isCollapsed" />
						<span v-if="!isCollapsed">Provider</span>
					</router-link>
				</li>
				<li class="p-2 hover:bg-gray-700 flex items-center">
					<router-link to="/users" class="block w-full text-left text-white">
						<Users class="mr-2" v-if="isCollapsed" />
						<span v-if="!isCollapsed">Users</span>
					</router-link>
				</li>
				<li class="p-2 hover:bg-gray-700 flex items-center" v-if="store.state.user.userId === 1">
					<router-link to="/lab" class="block w-full text-left text-white">
						<FlaskConical class="mr-2" v-if="isCollapsed" />
						<span v-if="!isCollapsed">LAB</span>
					</router-link>
				</li>
				<li class="p-2 hover:bg-gray-700 flex items-center" v-if="store.state.user.userId === 1">
					<router-link to="/assistant" class="block w-full text-left text-white">
						<FlaskConical class="mr-2" v-if="isCollapsed" />
						<span v-if="!isCollapsed">Assistant</span>
					</router-link>
				</li>
				<li class="p-2 hover:bg-gray-700 flex items-center" v-if="store.state.user.userId === 1">
					<router-link to="/docs" class="block w-full text-left text-white">
						<FlaskConical class="mr-2" v-if="isCollapsed" />
						<span v-if="!isCollapsed">Docs</span>
					</router-link>
				</li>

				<li class="p-2 hover:bg-gray-700 flex items-center">
					<a href="/logout" class="block w-full text-left text-white">
						<LogOut class="mr-2" v-if="isCollapsed" />
						<span v-if="!isCollapsed">Log Out</span>
					</a>
				</li>
			</ul>
		</nav>
	</div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { Home, User, Users, MessageCircle, Image, Settings, FlaskConical, LogOut } from 'lucide-vue-next';

export default {
	components: {
		Home,
		User,
		Users,
		MessageCircle,
		Image,
		Settings,
		FlaskConical,
		LogOut
	},
	setup() {
		const store = useStore();
		const isCollapsed = ref(false);

		const toggleSidebar = () => {
			isCollapsed.value = !isCollapsed.value;
		};

		const handleResize = () => {
			isCollapsed.value = window.innerWidth < 768;
		};

		onMounted(() => {
			handleResize();
			window.addEventListener('resize', handleResize);
		});

		onBeforeUnmount(() => {
			window.removeEventListener('resize', handleResize);
		});

		return {
			store,
			isCollapsed,
			toggleSidebar
		};
	}
};
</script>

<style scoped>
.transition-width {
	transition: width 0.3s;
}
</style>