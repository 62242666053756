<template>
	<div class="mx-auto">
		<h2 class="text-left text-white font-bold text-3xl pb-3 pl-2">Chat</h2>
		<div class="p-5 bg-gray-100/75 rounded-lg shadow-md">

			<div class="tabs mb-0">
				<button @click="$router.push({ name: 'Chat' })">List Chats</button>
				<button class="active">Chat Detail</button>
			</div>


			<div ref="chatContainer" class="chat-container p-4">
				<div v-if="chat">
					<div class="flex items-center mb-4">
						<img :src="chat.roleCharacter.resources[0].resourceUrl" alt="Player Image"
							class="h-24 w-24 rounded-full mr-4" v-if="chat.roleCharacter.resources.length > 0">
						<div>
							<h2 class="text-xl font-bold">{{ chat.player.userName }}</h2>
							<p class="text-gray-600">{{ chat.roleCharacter.name }}</p>
							<p class="text-gray-600" v-if="chat.imageGenerationModel">{{ chat.imageGenerationModel.name
								}}</p>
						</div>
					</div>
					<div class="flex items-center">
						<ul class="mb-4 w-3/4 mx-auto">
							<li v-for="message in chat.messages" :key="message.token" class="mb-7">
								<div class="flex justify-between items-center">
									<button @click="generateImage(message.token)"
										v-if="message.type === 'system' && !message.isGenerating"
										:disabled="(message.state && message.state !== 'done') || message.isGenerating"
										class="btn btn-primary mt-4 inline-flex items-center gap-1 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
										<UserPlus class="w-4 h-4" />
										<CirclePlay class="w-4 h-4"
											v-if="message.message && message.message.includes('<GenerateImage>')" />
										Generate Image
									</button>
									<div v-if="message.isGenerating"
										class="mt-4 inline-flex items-center gap-1 bg-green-400 text-white font-bold py-2 px-4 rounded">
										<Loader class="animate-spin" />
										<p class="text-sm italic animate-hearth">Generating image...</p>

									</div>
									<div v-if="message.isGenerating || message.generationDuration"
										class="mt-4 inline-flex items-center gap-1 bg-green-400 text-white font-bold py-2 px-4 rounded">
										<p class="text-sm italic animate-hearth">Generated in {{
											message.generationDuration }}s</p>
									</div>
								</div>


								<div
									:class="['p-5 rounded w-3/4', message.type === 'system' ? 'bg-gray-100/75 text-left ml-0' : 'bg-white/85 text-left ml-auto']">

									<div v-if="message.type === 'system' && message.state && message.state === 'waiting.for.response'"
										class="animate-hearth inline-flex items-center gap-1">
										<!-- <HeartPulse class="w-4 h-4 text-red-500" /> -->
										<Brain class="w-4 h-4 text-indigo-800" />
										<span class="italic text-indigo-600">Thinking...</span>
									</div>

									<div v-if="message.type === 'system' && message.state && message.state === 'streaming'"
										class="animate-hearth inline-flex items-center gap-1">
										<!-- <HeartPulse class="w-4 h-4 text-red-500" /> -->
										<PencilLine class="w-4 h-4 text-indigo-800" />
										<span class="italic text-indigo-600">Writing...</span>
									</div>


									<p class="text-gray-800" v-html="message.message"></p>
									<p class="text-gray-500 text-sm">{{ message.dateCreated }}</p>
									<div v-if="message.resources && message.resources.length > 0"
										class="mt-4 grid grid-cols-3 gap-4">
										<img :src="resource.resourceUrl" alt="Resource Image"
											class="max-w-[250px] max-h-[250px] rounded-md cursor-pointer"
											@click="openModal(resource.resourceUrl)"
											v-for="resource in message.resources" :key="resource.token" />

									</div>



								</div>
							</li>
						</ul>
					</div>
					<div class="chat-footer flex" ref="chatFooter">
						<input v-model="newMessage" type="text" class="flex-1 p-2 border rounded-l"
							placeholder="Type a message" :disabled="isLoading" @keyup.enter="publishMessage">

						<!-- <button @click="sendMessage" class="bg-blue-500 hover:bg-blue-400 text-white p-2 rounded-r"
							:disabled="isLoading" title="Send sync">
							<Loader v-if="isLoading" class="animate-spin" />
							<Send v-if="!isLoading" />
						</button> -->
						<button @click="publishMessage" class="bg-blue-500 hover:bg-blue-400 text-white p-2 rounded-r"
							:disabled="isLoading" title="Send asyc (stream event)">
							<Loader v-if="isLoading" class="animate-spin" />
							<Send v-if="!isLoading" />
						</button>
					</div>
				</div>
				<div v-else>
					<p>Loading chat...</p>
				</div>
			</div>
		</div>

		<ImageModal @close="isModalOpen = false" :modalImageUrl="modalImageUrl" :isModalOpen="isModalOpen" />
	</div>
</template>

<script>
/* eslint-disable */
import { ref, onMounted, nextTick } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { Loader, UserPlus, HeartPulse, Brain, PencilLine, Send, CirclePlay } from 'lucide-vue-next';
import ImageModal from '../ImageModal.vue';

export default {
	props: {
		token: {
			type: String,
			required: true
		}
	},
	components: {
		Loader, ImageModal,
		UserPlus, HeartPulse, Brain, PencilLine, Send, CirclePlay
	},
	setup(props) {
		const router = useRoute();
		const toast = useToast();
		const store = useStore();
		const accessToken = store.state.user.accessToken;

		const chat = ref(null);
		const newMessage = ref('');
		const isLoading = ref(false);
		const chatContainer = ref(null);
		const chatFooter = ref(null);

		const isModalOpen = ref(false);
		const modalImageUrl = ref('');

		const scrollToBottom = () => {
			nextTick(() => {
				const el = chatFooter.value;
				if (el) {
					el.scrollIntoView({ behavior: 'smooth' });
				}
			});
		};

		const fetchChat = async () => {
			try {
				const response = await fetch(`/v1/api/chat/${router.params.token}`, {
					headers: {
						'Authorization': `Bearer ${accessToken}`
					}
				});
				chat.value = await response.json();
			} catch (error) {
				console.error('Error fetching chat:', error);
			}
		};

		const publishMessage = async () => {
			if (newMessage.value.trim() === '') return;

			chat.value.messages.push({
				message: newMessage.value,
				type: 'user',
				dateCreated: new Date().toLocaleString()
			});

			const message = newMessage.value;
			newMessage.value = '';
			isLoading.value = true;

			try {
				const response = await fetch(`/v1/api/chat/${router.params.token}/message:publish`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${accessToken}`
					},
					body: message
				});
				const messageData = await response.json();
				if (response.status === 200) {
					messageData.state = 'waiting.for.response';
					chat.value.messages.push(messageData);
					subscribeMessage(messageData.token);
				} else {
					toast.error('Failed to send message');
					console.error('Error sending message:', messageData);
				}
			} catch (error) {
				toast.error('Failed to send message');
				console.error('Error sending message:', error);
			} finally {
				scrollToBottom();
			}
		};

		const subscribeMessage = async (messageToken) => {
			try {
				const response = await fetch(`/v1/api/chat/${router.params.token}/message/${messageToken}/stream`, {
					headers: {
						'Accept': 'text/event-stream',
						'Authorization': `Bearer ${accessToken}`
					},
				});

				const reader = response.body.getReader();
				const decoder = new TextDecoder();
				let buffer = '';

				while (true) {
					const { value, done } = await reader.read();
					if (done) {
						if (buffer.trim().length > 0) {
							await processSSEChunk(buffer);
						}
						isLoading.value = false;
						break;
					}

					const chunk = decoder.decode(value, { stream: true });
					buffer += chunk;

					const messages = buffer.split('\n\n');
					for (let i = 0; i < messages.length - 1; i++) {
						await processSSEChunk(messages[i]);
					}
					buffer = messages[messages.length - 1];
				}
			} catch (error) {
				console.error('Error in SSE processing:', error);
			} finally {
				const lastMessage = chat.value.messages[chat.value.messages.length - 1];
				if (lastMessage) {
					lastMessage.state = 'done';
					chat.value.messages[chat.value.messages.length - 1] = lastMessage;
				}
				isLoading.value = false;
			}
		};

		const processSSEChunk = async (chunk) => {
			const lines = chunk.split('\n').filter(line => line.trim() !== '');

			for (const line of lines) {
				if (line.startsWith('data:')) {
					try {
						const dataContent = line.substring(5).trim();
						if (dataContent === '[DONE]') {
							// Handle the end of the stream if necessary
							continue;
						}
						// Parse the JSON content

						const jsonData = JSON.parse(dataContent);
						const content = jsonData.content;
						const lastMessage = chat.value.messages[chat.value.messages.length - 1];

						if (lastMessage) {
							lastMessage.state = 'streaming';
							lastMessage.message = lastMessage.message ?? '';
							lastMessage.message += content;
							chat.value.messages[chat.value.messages.length - 1] = { ...lastMessage };
						}
					} catch (error) {
						console.error('Error processing SSE line:', error, 'Line:', line);
					}
				}
			}

			await scrollToBottom();
		};

		const sendMessage = async () => {
			if (newMessage.value.trim() === '') return;

			isLoading.value = true;

			const message = newMessage.value;
			newMessage.value = '';

			chat.value.messages.push({
				message: message,
				type: 'player',
				dateCreated: new Date().toLocaleString()
			});

			scrollToBottom();

			try {
				const response = await fetch(`/v1/api/chat/${router.params.token}/message`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${accessToken}`
					},
					body: message
				});
				const systemMessage = await response.json();
				if (response.status === 200) {
					chat.value.messages.push(systemMessage);
				} else {
					toast.error('Failed to send message');
					console.error('Error sending message:', systemMessage);
				}
			} catch (error) {
				toast.error('Failed to send message');
				console.error('Error sending message:', error);
			} finally {
				isLoading.value = false;
				scrollToBottom();
			}
		};

		const generateImage = async (messageToken) => {
			const messageIndex = chat.value.messages.findIndex(msg => msg.token === messageToken);
			if (messageIndex !== -1) {
				chat.value.messages[messageIndex].isGenerating = true;
				chat.value.messages[messageIndex].generationStartTime = Date.now();
				try {
					const response = await fetch(`/v1/api/chat/${router.params.token}/message/${messageToken}/generateimage`, {
						method: 'GET',
						headers: {
							'Authorization': `Bearer ${accessToken}`
						}
					});

					if (response.ok) {
						const updatedMessage = await response.json();
						updatedMessage.isGenerating = false;
						updatedMessage.generationDuration = ((Date.now() - chat.value.messages[messageIndex].generationStartTime) / 1000).toFixed(2);
						chat.value.messages[messageIndex] = updatedMessage;
						toast.success('Image generated successfully');
					} else {
						toast.error('Failed to generate image');
					}
				} catch (error) {
					toast.error('Error generating image');
				}
			}
		};

		const openModal = (imageUrl) => {
			isModalOpen.value = true;
			modalImageUrl.value = imageUrl;
			console.log('Opening modal for image:', modalImageUrl);
		};

		onMounted(() => {
			fetchChat().then(() => {
				scrollToBottom();
			});
		});

		return {
			chat,
			newMessage,
			isLoading,
			chatContainer,
			chatFooter,
			scrollToBottom,
			fetchChat,
			publishMessage,
			subscribeMessage,
			processSSEChunk,
			sendMessage,
			generateImage,
			openModal,
			isModalOpen,
			modalImageUrl
		};
	}
};
</script>

<style>
.animate-spin {
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.animate-hearth {
	animation: pulse 1s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}

	50% {
		transform: scale(1.1);
	}

	100% {
		transform: scale(1);
	}
}

GenerateImage {
	display: none;
}
</style>