<template>
	<div class="mx-auto px-5 py-2">
		<h2 class="text-left text-white font-bold text-3xl pb-3 pl-2">Edit Character</h2>
		<div class="p-5 bg-gray-100/75 rounded-lg shadow-md">
			<div class="tabs mb-0">
				<button :class="{ 'active': activeTab === 'details' }" @click="activeTab = 'details'">Character
					Details</button>
				<button :class="{ 'active': activeTab === 'images' }" @click="activeTab = 'images'">Character
					Images</button>
			</div>

			<!-- Character Details Tab -->
			<div v-if="activeTab === 'details'" class="bg-white p-5 rounded-lg shadow">
				<form @submit.prevent="saveCharacter" class="space-y-4">
					<div class="flex flex-col gap-2">
						<label for="name" class="text-sm font-medium text-gray-700">Name</label>
						<input type="text" id="name" v-model="character.name" placeholder="Enter character name"
							class="mt-1 block w-full p-2 border rounded-md" required />
					</div>

					<div class="flex flex-col gap-2">
						<label for="brief" class="text-sm font-medium text-gray-700">Description</label>
						<textarea id="brief" v-model="character.brief" placeholder="Enter character description"
							rows="4" class="mt-1 block w-full p-2 border rounded-md" required></textarea>
					</div>

					<div class="flex flex-col gap-2">
						<label for="tags" class="text-sm font-medium text-gray-700">Tags</label>
						<input type="text" id="tags" v-model="character.tags" placeholder="Enter tags (comma separated)"
							class="mt-1 block w-full p-2 border rounded-md" required />
					</div>

					<div class="flex flex-col gap-2">
						<label for="imagegenerationmodelid" class="text-sm font-medium text-gray-700">Image
							Model</label>
						<select id="imagegenerationmodelid" v-model="character.imageGenerationModelId"
							class="mt-1 block w-full p-2 border rounded-md" required>
							<option v-for="model in imageModels" :key="model.imagegenerationmodelid"
							:selected="model.imageGenerationModelId == character.imageGenerationModelId"
								:value="model.imageGenerationModelId">
								{{ model.name }}
							</option>
						</select>
					</div>

					<button type="submit" class="btn btn-primary inline-flex items-center gap-1">
						<UserPlus class="w-4 h-4" />
						Save Character
					</button>
				</form>
			</div>

			<!-- Character Images Tab -->
			<div v-if="activeTab === 'images'" class="bg-white p-5 rounded-lg shadow">
				<CharacterImages :token="character.token" />
			</div>

		</div>
	</div>
</template>

<script>
/* eslint-disable */
import { ref, onMounted, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { UserPlus } from 'lucide-vue-next';
import CharacterImages from './CharacterImages.vue';

export default {
	components: {
		UserPlus,
		CharacterImages,
	},
	setup() {
		const route = useRoute();
		const store = useStore();
		const accessToken = store.state.user.accessToken;
		const toast = useToast();
		const activeTab = ref('details');
		const imageModels = ref([]);
		const character = ref({
			token: '',
			name: '',
			brief: '',
			tags: '',
			status: 'active',
			imageGenerationModel: {
				imagegenerationmodelid: ''
			}
		});

		const fetchCharacter = async () => {
			try {
				const response = await fetch(`/v1/api/rolecharacter/${route.params.token}`, {
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${accessToken}`
					}
				});
				if (response.ok) {
					const data = await response.json();
					character.value = data;
				} else {
					toast.error('Failed to fetch character details');
				}
			} catch (error) {
				toast.error('Error fetching character details');
			}
		};

		const saveCharacter = async () => {
			try {
				character.value.imageGenerationModel = {
					imagegenerationmodelid: character.value.imageGenerationModel
				};
				const response = await fetch(`/v1/api/rolecharacter/${character.value.token}`, {
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${accessToken}`
					},
					body: JSON.stringify(character.value)
				});

				if (response.ok) {
					toast.success('Character updated successfully');
				} else {
					toast.error('Failed to update character');
				}
			} catch (error) {
				toast.error('Error updating character');
			}
		};

		const fetchImageModels = async () => {
			try {
				const response = await fetch('/v1/api/rolecharacter/imagegenerationmodel', {
					headers: {
						'Authorization': `Bearer ${accessToken}`
					}
				});
				if (response.ok) {
					const data = await response.json();
					imageModels.value = data;
				} else {
					toast.error('Failed to fetch image models');
				}
			} catch (error) {
				console.error('Error fetching image models:', error);
				toast.error('Failed to fetch image models');
			}
		};
		onBeforeMount(async () => {
			await fetchImageModels();
			await fetchCharacter();
		});
		onMounted(async () => {
		});

		return {
			activeTab,
			character,
			saveCharacter,
			imageModels,
			fetchImageModels
		};
	}
};
</script>

<style scoped>
.tabs {
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
}

.tabs button {
	padding: 0.5rem 1rem;
	border: none;
	cursor: pointer;
	border-radius: 0.375rem;
}

.tabs button.active {
	background-color: #4f46e5;
	color: white;
}

.btn {
	padding: 0.5rem 1rem;
	border-radius: 0.375rem;
	font-weight: 500;
	cursor: pointer;
	transition: background-color 0.2s;
}

.btn-primary {
	background-color: #4f46e5;
	color: white;
}

.btn-danger {
	background-color: #dc2626;
	color: white;
}

.btn-primary:hover {
	background-color: #4338ca;
}

.btn-danger:hover {
	background-color: #b91c1c;
}

.btn:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}
</style>