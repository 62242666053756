import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '../components/Dashboard.vue';
import Login from '../components/Login.vue';
import Player from '../components/Player.vue';
import Character from '../components/character/Character.vue';
import CharacterEdit from '../components/character/CharacterEdit.vue';
import CharacterImages from '../components/character/CharacterImages.vue';
import ImageGenerationModel from '../components/ImageGenerationModel.vue';
import Chat from '../components/chat/Chat.vue';
import ChatDetail from '../components/chat/ChatDetail.vue';
import Lab from '../components/Lab.vue';
import UserManagement from '../components/UserManagement.vue';
import Provider from '../components/Provider.vue';
import Documentation from '../components/Documentation.vue';
import Assistant from '../components/assistant/Assistant.vue';
import store from '../store';

const routes = [
	{ path: '/', name: 'Dashboard', component: Dashboard },
	{ path: '/dashboard', name: 'Dashboard', component: Dashboard },
	{ path: '/login', name: 'Login', component: Login },
	{ path: '/player', name: 'Player', component: Player },
	{ path: '/provider', name: 'Provider', component: Provider },
	{ path: '/imagegenerationmodel', name: 'ImageGenerationModel', component: ImageGenerationModel },
	{ path: '/lab', name: 'Lab', component: Lab },
	{ path: '/assistant', name: 'Assistant', component: Assistant },
	{ path: '/docs', name: 'Documentation', component: Documentation },
	{
		path: '/users',
		name: 'UserManagement',
		component: UserManagement,
		children: [
			{
				path: ':id/edit',
				name: 'UserEdit',
				component: UserManagement,
				props: true
			}
		]
	},
	{
		path: '/character',
		children: [
			{
				path: '',
				name: 'CharacterList',
				component: Character // This will handle the list view
			},
			{
				path: ':token',
				name: 'CharacterEdit',
				component: CharacterEdit,
				props: true
			},
			{
				path: ':token/images',
				name: 'CharacterImages',
				component: CharacterImages,
				props: true
			}
		]
	},
	{
		path: '/chat',
		children: [
			{
				path: '',
				name: 'Chat',
				component: Chat
			},
			{
				path: ':token',
				name: 'ChatDetail',
				component: ChatDetail,
				props: true
			}
		]
	}

];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach((to, from, next) => {
	fetch('/checksession')
		.then((response) => response.json())
		.then((data) => {
			if (!data.status) {
				store.dispatch('clearUser');
				if (to.path !== '/login') {
					next('/login');
				} else {
					next();
				}
			} else {
				const user = {
					name: data.content.name,
					email: data.content.email,
					accessToken: data.content.apiAuths[0]?.apiSecret,
					userId: data.content.userId,

				};
				store.dispatch('setUser', user);
				next();
			}
		})
		.catch(() => {
			store.dispatch('clearUser');
			if (to.path !== '/login') {
				next('/login');
			} else {
				next();
			}
		});
});

export default router;
