<!-- generate a modern look and feel, easy to use, user friendly component to list chats qnd create new chat with required information.
use table layout for chat list. each chat line must contains token, and a generic button. 
the general layout contains 2 tabs: create chat and list chat. 
the create chat tab must contains a form with all necessary inputs from Chat.java,model.
in chat creation, first select a player from the list of players, then select a role character from the list of role characters, then select a image generation model from the list of image generation models and use fetch api to create a chat as described inside ChatController.java.
use modern, minimal user-friendly and stylish look and feel while using tailwind css. also use lucide-vue-next (EXAMPLE import: import { CheckCircle, XCircle } from 'lucide-vue-next';) icons when necessary.
use toast (exmple inport: import { useToast } from 'vue-toastification';) messages if necessary. 
since you have access to whole projects files, you can find the necessary spring boot java files for informations about fetch api requests; request and response models. 
it's necessary to confirm that yu can read and understand followinf project files:
ChatController.java, Chat.java, RoleCharacter.java, Player.java
if you can't read or not understand do not generate anything because it's necessary to understand the project files to generate the component.


 -->
<template>
	<div class="container mx-auto p-4">
		<h2 class="text-left text-white font-bold text-3xl pb-3 pl-2">Chat</h2>
		<div class="p-5 bg-gray-100/75 rounded-lg shadow-md">
			
			<div class="tabs mb-0">
				<button :class="{ 'active': activeTab === 'list' }" @click="activeTab = 'list'">List Chats</button>
				<button :class="{ 'active': activeTab === 'create' }" @click="activeTab = 'create'">Create Chat</button>
			</div>
			<div v-if="activeTab === 'list'" class="table-auto bg-white p-5">
				<table class="min-w-full ">
					<thead>
						<tr>
							<th class="hidden lg:flex py-2">Date</th>
							<th class="py-2">Player</th>
							<th class="py-2">Role character</th>
							<th class="py-2">Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="chat in chats" :key="chat.token">
							<td class="hidden lg:flex py-2">{{ new Date(chat.dateCreated).toLocaleString() }}</td>
							<td class="py-2">{{ chat.player.userName }}</td>
							<td class="py-2">{{ chat.roleCharacter.name }}</td>
							<td class="py-2">
								<button @click="goToChat(chat.token)" class="btn btn-primary text-xs px-0 py-1">
									<MessageSquareText size="16" />
								</button>
								<button @click="deleteChat(chat.token)" class="hidden lg:inline btn btn-danger text-xs px-0 py-1 bg-red-500 hover:bg-red-700">
									<XCircle size="16" />
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div v-if="activeTab === 'create'">
				<form @submit.prevent="createChat" class="space-y-4">
					<div class="bg-gradient-to-tr from-slate-100 to-gray-200 p-4 rounded-lg">

						<div class="mb-4">
							<label for="player" class="block text-sm font-medium text-gray-700">Player</label>
							<select v-model="newChat.playerToken" id="player" class="mt-1 block w-full p-2">
								<option value="">Select a player [Total {{ players.length }}]</option>
								<option v-for="player in players" :key="player.token" :value="player.token">{{
									player.userName }}</option>
							</select>

						</div>
						<div class="mt-8 mb-4">
							<label for="roleCharacter" class="block text-sm font-medium text-gray-700">Role
								Character</label>
							<select v-model="newChat.roleCharacterToken" id="roleCharacter" class="mt-1 block w-full p-2">
								<option value="">Select a character [Total {{ roleCharacters.length }}]</option>
								<option v-for="role in roleCharacters" :key="role.token" :value="role.token">{{
									role.name }}</option>
							</select>
						</div>
					</div>
					<button type="submit" class="btn btn-primary"
						:disabled="!newChat.playerToken || !newChat.roleCharacterToken">Create Chat</button>
				</form>
			</div>
			<div v-if="!!activeChatToken">
				<ChatDetail :token="activeChatToken" />
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */ 
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import ChatDetail from './ChatDetail.vue';
import { CheckCircle, XCircle, MessageSquareText } from 'lucide-vue-next';

export default {
	components: {
		ChatDetail, CheckCircle, XCircle, MessageSquareText 
	},
	setup() {
		const route = useRoute();
		const router = useRouter();
		const store = useStore();
		const accessToken = store.state.user.accessToken;
		const activeTab = ref('list');
		const chats = ref([]);
		const players = ref([]);
		const roleCharacters = ref([]);
		const newChat = ref({
			playerToken: '',
			roleCharacterToken: ''
		});
		const toast = useToast();
		const activeChatToken = ref('');

		const fetchChats = async () => {
			// Fetch chats from API /v1/api/player/ 
			const response = await fetch('/v1/api/chat', {
				headers: {
					'Authorization': `Bearer ${accessToken}`
				}
			});
			if (response.ok) {
				const data = await response.json();
				chats.value = data;

				for (let chat of chats.value) {
					chat.player = players.value.find(p => p.token === chat.playerToken);
					chat.roleCharacter = roleCharacters.value.find(r => r.token === chat.roleCharacterToken);
				}
			} else {
				toast.error('Failed to fetch chats');
			}
		};

		const fetchPlayers = async () => {
			const response = await fetch('/v1/api/player', {
				headers: {
					'Authorization': `Bearer ${accessToken}`
				}
			});
			if (response.ok) {
				const data = await response.json();
				players.value = data;
			} else {
				toast.error('Failed to fetch chats');
			}
		};

		const fetchRoleCharacters = async () => {
			// Fetch role characters from API /v1/api/rolecharacter/
			const response = await fetch('/v1/api/rolecharacter', {
				headers: {
					'Authorization': `Bearer ${accessToken}`
				}
			});
			if (response.ok) {
				const data = await response.json();
				roleCharacters.value = data;
			} else {
				toast.error('Failed to fetch chats');
			}
		};

		const createChat = async () => {
			console.log(newChat.value);
			const requestPayload = {
				playerToken: newChat.value.playerToken,
				roleCharacterToken: newChat.value.roleCharacterToken
			};
			try {
				const response = await fetch('/v1/api/chat', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${accessToken}`
					},
					body: JSON.stringify(requestPayload)
				});
				const data = await response.json();
				if (response.status === 201) {
					chats.value = [data, ...chats.value];
					newChat.value = {
						playerToken: '',
						roleCharacterToken: ''
					};

					// go to chat detail
					activeChatToken.value = data.token;
					activeTab.value = 'detail';
					router.push({ name: 'ChatDetail', params: { token: data.token } });


					toast.success('Chat created successfully');
				} else {
					//toast.error('Failed to create chat: ' + data.message, { timeout: 10000 + (1.618 * data.message.length) });
					toast.error('Failed to create chat');
					console.error('Failed to create chat');
				}




			} catch (error) {
				toast.error('Failed to create chat');
			}
		};

		const goToChat = (token) => {
			console.log('Action clicked for chat token:', token);
			activeChatToken.value = token;
			activeTab.value = 'detail';
			router.push({
				name: 'ChatDetail',
				params: { token: token }
			});
		};

		const deleteChat = async (token) => {
			if (confirm('Are you sure you want to delete this chat?')) {
				try {
					const response = await fetch(`/v1/api/chat/${token}`, {
						method: 'DELETE',
						headers: {
							'Authorization': `Bearer ${accessToken}`
						}
					});

					if (response.ok) {
						toast.success('Chat deleted successfully');
						await fetchChats();
					} else {
						toast.error('Failed to delete chat');
					}
				} catch (error) {
					toast.error('Error deleting chat');
				}
			}
		};

		onMounted( async () => {
			await fetchPlayers();
			await fetchRoleCharacters();
			fetchChats();
		});

		return {
			activeTab,
			chats,
			players,
			roleCharacters,
			newChat,
			createChat,
			goToChat,
			deleteChat,
			activeChatToken
		};
	}
};
</script>

<style scoped>
.tabs {
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
}

.tabs button {
	padding: 0.5rem 1rem;
	border: none;
	cursor: pointer;
}

.tabs button.active {
	background-color: #4f46e5;
	color: white;
}

.btn {
	padding: 0.5rem 1rem;
	background-color: #4f46e5;
	color: white;
	border: none;
	cursor: pointer;
}

.btn-primary {
	background-color: #4f46e5;
}

.btn-primary:hover {
	background-color: #4338ca;
}
.btn-danger {
	background-color: #dc2626;
	color: white;
}
</style>