import { createStore } from 'vuex'

export default createStore({
	state: {
		user: {
			email: '',
			fullname: '',
			accessToken: '',
		}
	},
	getters: {
		user: state => state.user
	},
	mutations: {
		setUser(state, user) {
			state.user = user;
		},
		clearUser(state) {
			state.user = {
				email: '',
				fullname: '',
				accessToken: '',
			};
		}
	},
	actions: {
		setUser(context, user) {
			context.commit('setUser', user);
		},
		clearUser(context) {
			context.commit('clearUser');
		},
	},
	modules: {

	}
})
