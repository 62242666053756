<template>
	<div class="container mx-auto p-4">
		<table class="min-w-full bg-white">
			<thead>
				<tr>
					<th class="py-2 px-4 border-b">ID</th>
					<th class="py-2 px-4 border-b">Name</th>
					<th class="py-2 px-4 border-b">Description</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="model in models" :key="model.imageGenerationModelId">
					<td class="py-2 px-4 border-b">{{ model.imageGenerationModelId }}</td>
					<td class="py-2 px-4 border-b">{{ model.name }}</td>
					<td class="py-2 px-4 border-b">{{ model.description }}</td>

				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { ref, onMounted } from 'vue';

export default {
	components: {
	},
	setup() {
		const models = ref([]);
		const store = useStore();
		const accessToken = store.state.user.accessToken;

		onMounted(async () => {
			const response = await fetch('/v1/api/rolecharacter/imagegenerationmodel', {
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			});
			const data = await response.json();
			models.value = data;
		});

		return {
			models,
		};
	},
};
</script>

<style scoped>
.container {
	max-width: 1200px;
}
</style>