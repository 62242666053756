<template>
	<div class="max-w-7xl mx-auto p-6">
		<button @click="trainModel" class="btn btn-primary">Train LORA model</button>
		<button @click="selectAllForTraining" class="btn btn-secondary">Select All for Training</button>
		<div class="p-5 bg-gray-100/75 rounded-lg shadow-md">
			<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
				<div v-for="image in images" :key="image.token" class="relative">
					<img :src="image.resourceUrl"
						alt="Character Image" class="w-full h-auto rounded-md" />
					<button @click="deleteImage(image.token)" class="absolute top-2 right-2 btn btn-danger">
						<UserMinus class="w-4 h-4" />
					</button>
					<!-- <input type="checkbox"
						@change="trainImages = $event.target.checked ? [...trainImages, image.id.resourceId] : trainImages.filter(id => id !== image.id.resourceId)"
						:checked="trainImages.includes(image.id.resourceId)" /> -->
				</div>
			</div>
			<div class="mt-4">
				<h3 class="text-lg font-medium mb-2">Upload New Image</h3>
				<input type="file" @change="handleFileUpload"
					class="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
					multiple accept="image/*" />
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'vue-toastification';
import { UserMinus } from 'lucide-vue-next';

export default {
	name: 'CharacterImages',
	components: {
		UserMinus
	},
	setup() {
		const route = useRoute();
		const images = ref([]);
		const toast = useToast();
		const trainImages = ref([]);
		const store = useStore();
		const accessToken = store.state.user.accessToken;

		const fetchImages = async () => {
			try {
				const response = await fetch(`/v1/api/rolecharacter/${route.params.token}/resources`, {
					method: 'GET',
					headers: {
						'Authorization': `Bearer ${accessToken}`
					}
				});
				if (response.ok) {
					const data = await response.json();
					images.value = data;
				} else {
					toast.error('Failed to fetch images');
				}
			} catch (error) {
				toast.error('Error fetching images');
			}
		};

		const deleteImage = async (token) => {
			if (confirm('Are you sure you want to delete this image?')) {
				try {
					const response = await fetch(`/v1/api/rolecharacter/${route.params.token}/resources/${token}`, {
						method: 'DELETE',
						headers: {
							'Authorization': `Bearer ${accessToken}`
						}
					});

					if (response.ok) {
						toast.success('Image deleted successfully');
						await fetchImages();
					} else {
						toast.error('Failed to delete image');
					}
				} catch (error) {
					toast.error('Error deleting image');
				}
			}
		};

		const selectedFiles = ref([]);

		const handleFileUpload = (event) => {
			selectedFiles.value = Array.from(event.target.files);
			uploadImages();
		};

		const toBase64 = (file) => {
			return new Promise((resolve, reject) => {
				const reader = new FileReader();
				reader.readAsDataURL(file);
				reader.onload = () => resolve(reader.result);
				reader.onerror = error => reject(error);
			});
		};

		const uploadImages = async () => {
			const base64ResourceContents = await Promise.all(selectedFiles.value.map(file => toBase64(file)));
			const payload = {
				roleCharacterToken: route.params.token,
				base64ResourceContents
			};

			try {
				const response = await fetch(`/v1/api/rolecharacter/${route.params.token}/resources`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${accessToken}`
					},
					body: JSON.stringify(payload)
				});

				if (response.ok) {
					toast.success('Images uploaded successfully');
					await fetchImages();
					selectedFiles.value = [];
				} else {
					toast.error('Failed to upload images');
				}
			} catch (error) {
				toast.error('Error uploading images');
			}
		};

		const trainModel = async () => {
			try {
				if (trainImages.value.length === 0) {
					toast.error('Please select images to train the model');
					return;
				}

				const response = await fetch(`/v1/api/rolecharacter/${route.params.token}/train`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${accessToken}`
					},
					body: JSON.stringify(trainImages.value)
				});

				if (response.ok) {
					toast.success('Training request submitted successfully');
				} else {
					const error = await response.json();
					toast.error(error.message || 'Failed to submit training request');
				}
			} catch (error) {
				toast.error('Error submitting training request');
			}
		};

		const selectAllForTraining = () => {
			trainImages.value = images.value.map(image => image.id.resourceId);
			console.log(trainImages.value);
		};

		onMounted(fetchImages);

		return {
			images,
			deleteImage,
			handleFileUpload,
			trainModel,
			trainImages,
			selectAllForTraining
		};
	}
};
</script>

<style>
.btn {
	padding: 0.5rem 1rem;
	border-radius: 0.375rem;
	font-weight: 500;
	cursor: pointer;
	transition: background-color 0.2s;
}

.btn-danger {
	background-color: #dc2626;
	color: white;
}

.btn-danger:hover {
	background-color: #b91c1c;
}
</style>