<template>
	<div class="mx-auto px-5 py-2">
		<h2 class="text-left text-white font-bold text-3xl pb-3 pl-2">Provider Management</h2>
		<div class="p-5 bg-gray-100/75 rounded-lg shadow-md">
			<div class="tabs mb-0">
				<button :class="{ 'active': activeTab === 'list' }" @click="activeTab = 'list'">List Providers</button>
				<button :class="{ 'active': activeTab === 'details' }" @click="activeTab = 'details'"
					:disabled="!selectedProvider">Provider Details</button>
			</div>

			<!-- List Providers Tab -->
			<div v-if="activeTab === 'list'" class="table-auto bg-white p-5">
				<table class="min-w-full">
					<thead>
						<tr>
							<th class="py-2 px-4 border-b">ID</th>
							<th class="py-2 px-4 border-b">Type</th>
							<th class="py-2 px-4 border-b">Name</th>
							<th class="py-2 px-4 border-b">Is Active?</th>
							<th class="py-2 px-4 border-b">Is Default</th>
							<th class="py-2 px-4 border-b">Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="provider in providers" :key="provider.providerId">
							<td class="py-2 px-4 border-b">{{ provider.providerId }}</td>
							<td class="py-2 px-4 border-b">{{ provider.generationType }}</td>
							<td class="py-2 px-4 border-b">{{ provider.name }}</td>
							<td class="py-2 px-4 border-b">{{ provider.isActive }}</td>
							<td class="py-2 px-4 border-b">{{ provider.isDefault }}</td>
							<td class="py-2 px-4 border-b space-x-2 flex items-center justify-center">
								<button @click="editProvider(provider)"
									class="btn btn-primary inline-flex items-center gap-1">
									<UserPen class="w-4 h-4" />
									Edit
								</button>
								<button @click="deleteProvider(provider.providerId)"
									class="btn btn-danger inline-flex items-center gap-1">
									<UserMinus class="w-4 h-4" />
									Delete
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<!-- Provider Details Tab -->
			<div v-if="activeTab === 'details'" class="bg-white p-5">
				<h2 class="text-xl font-semibold mb-2">Provider Details</h2>
				<form @submit.prevent="saveProvider" class="space-y-4">
					<div class="flex flex-col gap-2">
						<label for="name" class="text-sm font-medium text-gray-700">Name</label>
						<input type="text" id="name" v-model="selectedProvider.name"
							class="mt-1 block w-full p-2 border rounded-md" required />
					</div>
					<div class="flex flex-col gap-2">
						<label for="generationType" class="text-sm font-medium text-gray-700">Generation Type</label>
						<input type="text" id="generationType" v-model="selectedProvider.generationType"
							class="mt-1 block w-full p-2 border rounded-md" required disabled />
					</div>
					<div class="flex items-center gap-2">
						<input type="checkbox" id="isActive" v-model="selectedProvider.isActive"
							class="mt-1" />
						<label for="isActive" class="text-sm font-medium text-gray-700">Is Active?</label>
					</div>
					<div class="flex items-center gap-2">
						<input type="checkbox" id="isDefault" v-model="selectedProvider.isDefault"
							class="mt-1" />
						<label for="isDefault" class="text-sm font-medium text-gray-700">Is Default?</label>
					</div>
					<button type="submit" class="btn btn-primary inline-flex items-center gap-1">
						<UserPlus class="w-4 h-4" />
						Save Provider
					</button>
				</form>

				<h3 class="text-lg font-semibold mt-6 mb-2">Configs</h3>
				<table class="min-w-full bg-white">
					<thead>
						<tr>
							<th class="py-2 px-4 border-b">Config Name</th>
							<th class="py-2 px-4 border-b">Config Value</th>
							<th class="py-2 px-4 border-b">Actions</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="config in selectedProvider.configs" :key="config.id.configName">
							<td class="py-2 px-4 border-b">{{ config.id.configName }}</td>
							<td class="py-2 px-4 border-b">
								<input v-model="config.configValue" class="input" />
							</td>
							<td class="py-2 px-4 border-b space-x-2 flex items-center justify-center">
								<button @click="saveConfig(config)"
									class="btn btn-primary inline-flex items-center gap-1">
									<UserPlus class="w-4 h-4" />
									Save
								</button>
								<button @click="deleteConfig(config.id.configName)"
									class="btn btn-danger inline-flex items-center gap-1">
									<UserMinus class="w-4 h-4" />
									Delete
								</button>
							</td>
						</tr>
						<tr>
							<td class="py-2 px-4 border-b">
								<input v-model="newConfigName" class="input" placeholder="Config Name" />
							</td>
							<td class="py-2 px-4 border-b">
								<input v-model="newConfigValue" class="input" placeholder="Config Value" />
							</td>
							<td class="py-2 px-4 border-b space-x-2 flex items-center justify-center">
								<button @click="addConfig" class="btn btn-primary inline-flex items-center gap-1">
									<UserPlus class="w-4 h-4" />
									Add Config
								</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import { UserPen, UserMinus, UserPlus } from 'lucide-vue-next';

export default {
	components: {
		UserPen,
		UserMinus,
		UserPlus
	},
	setup() {
		const activeTab = ref('list');
		const providers = ref([]);
		const selectedProvider = ref(null);
		const newConfigName = ref('');
		const newConfigValue = ref('');
		const toast = useToast();

		const fetchProviders = async () => {
			const response = await fetch('/providermanagement');
			const data = await response.json();
			providers.value = data.content;
		};

		const editProvider = (provider) => {
			selectedProvider.value = { ...provider };
			activeTab.value = 'details';
		};

		const saveProvider = async () => {
			await fetch('/providermanagement', {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(selectedProvider.value)
			});
			toast.success('Provider saved successfully');
			fetchProviders();
			selectedProvider.value = null;
			activeTab.value = 'list';
		};

		const deleteProvider = async (providerId) => {
			await fetch(`/providermanagement/${providerId}`, {
				method: 'DELETE'
			});
			toast.success('Provider deleted successfully');
			fetchProviders();
		};

		const saveConfig = async (config) => {
			await fetch(`/providermanagement/${selectedProvider.value.providerId}/config`, {
				method: 'PUT',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(config)
			});
			toast.success('Config saved successfully');
			fetchProviders();
		};

		const deleteConfig = async (configName) => {
			await fetch(`/providermanagement/${selectedProvider.value.providerId}/config/${configName}`, {
				method: 'DELETE'
			});
			toast.success('Config deleted successfully');
			fetchProviders();
		};

		const addConfig = async () => {
			const newConfig = {
				id: { providerId: selectedProvider.value.providerId, configName: newConfigName.value },
				configValue: newConfigValue.value
			};
			await fetch(`/providermanagement/${selectedProvider.value.providerId}/config`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(newConfig)
			});
			toast.success('Config added successfully');
			selectedProvider.value.configs.push(newConfig);
			newConfigName.value = '';
			newConfigValue.value = '';
		};

		onMounted(fetchProviders);

		return {
			activeTab,
			providers,
			selectedProvider,
			newConfigName,
			newConfigValue,
			editProvider,
			saveProvider,
			deleteProvider,
			saveConfig,
			deleteConfig,
			addConfig
		};
	}
};
</script>

<style scoped>
.tabs {
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
}

.tabs button {
	padding: 0.5rem 1rem;
	border: none;
	cursor: pointer;
	border-radius: 0.375rem;
}

.tabs button.active {
	background-color: #4f46e5;
	color: white;
}

.btn {
	padding: 0.5rem 1rem;
	border-radius: 0.375rem;
	font-weight: 500;
	cursor: pointer;
	transition: background-color 0.2s;
}

.btn-primary {
	background-color: #4f46e5;
	color: white;
}

.btn-danger {
	background-color: #dc2626;
	color: white;
}

.btn-primary:hover {
	background-color: #4338ca;
}

.btn-danger:hover {
	background-color: #b91c1c;
}

.btn:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

.input {
	padding: 5px;
	border: 1px solid #ccc;
	border-radius: 4px;
	width: 100%;
}
</style>