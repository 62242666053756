<!-- 
This component will be using to test image generation and calibration for character model images.
we will have following inputs: 
- character selection 
- prompt textarea.
- generate button

when selecting a character, you will store role character token in the state, and display image generation model name next to do select box.
you can fetch the role characters from the api endpoint /v1/api/rolecharacter (returns: "[
    {
        "token": "a6b3bce5-392b-49ef-ab3c-98433240371a",
        "name": "Lexi",
        "imageGenerationModelId": 2,
		...")
you can fetch the image generation models info from /v1/api/rolecharacter/imagegenerationmodel (returns: "[
    {
        "imageGenerationModelId": 1,
        "name": "roberts",
        "description": null
    },...") then you can match the imageGenerationModelId with the role character's imageGenerationModelId to display the name of the model.

when clicking the generate button, you will submit a json contains "prompt" and "RoleCharacterToken". It will returns generated image's URL in the response. You can display the images in the div below the generate button. 

design:
keep the design simple and clean and harmonize with the rest of the app:
use tailwind css for styling 
use vue.js 3 composition api for the component 
use vue-toastification for the toast messages
use lucide icon components for the icons

-->
<template>
	<div class="mx-auto px-5 py-2">
		<h2 class="text-left text-white font-bold text-3xl pb-3 pl-2">LAB</h2>
		<div class="p-5 bg-gray-100/75 rounded-lg shadow-md">

			<div class="mb-4">
				<label class="block font-medium mb-1">Select Character:</label>
				<div class="flex items-center">
					<select v-model="selectedCharacterToken" @change="onCharacterChange"
						class="border rounded p-2 w-1/2">
						<option disabled value="">-- Choose a character --</option>
						<option v-for="character in roleCharacters" :key="character.token" :value="character.token">
							{{ character.name }}
						</option>
					</select>
					<span class="ml-4 text-gray-600">Model: {{ selectedModelName }}</span>
				</div>
			</div>

			<div class="mb-4">
				<label class="block font-medium mb-1">Prompt:</label>
				<textarea v-model="prompt" class="border rounded p-2 w-full h-32"
					placeholder="Enter your prompt here..."></textarea>
			</div>

			<div class="flex justify-between items-center">
				<button @click="generateImage" :disabled="isGenerating || !selectedCharacterToken || !prompt"
					class="btn btn-primary mt-4 inline-flex items-center gap-1 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
					<UserPlus class="w-4 h-4" />
					<CirclePlay class="w-4 h-4" />
					Generate Image
				</button>
				<div v-if="isGenerating"
					class="mt-4 inline-flex items-center gap-1 bg-green-400 text-white font-bold py-2 px-4 rounded">
					<Loader class="animate-spin" />
					<p class="text-sm italic animate-hearth">Generating image...</p>
				</div>
			</div>

			<div v-if="generatedImages.length" class="mt-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
				<div v-for="(image, index) in generatedImages" :key="index">
					<img :src="image" alt="Generated Image" class="w-full h-auto rounded shadow" />
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import { UserPlus, CirclePlay, Loader } from 'lucide-vue-next';

const toast = useToast();
const store = useStore();
const accessToken = store.state.user.accessToken;

const roleCharacters = ref([]);
const selectedCharacterToken = ref('');
const selectedModelName = ref('');
const prompt = ref('');
const generatedImages = ref([]);
const selectedCharacter = ref(null);
const isGenerating = ref(false);

const fetchRoleCharacters = async () => {
	try {
		const response = await fetch('/v1/api/rolecharacter', {
			headers: {
				'Authorization': `Bearer ${accessToken}`
			}
		});
		roleCharacters.value = await response.json();
	} catch (error) {
		toast.error('Failed to fetch role characters');
	}
};

const fetchCharacterInformation = async (token) => {
	try {
		const response = await fetch(`/v1/api/lab/characterinformation/${token}`, {
			headers: {
				'Authorization': `Bearer ${accessToken}`
			}
		});
		const data = await response.json();
		selectedCharacter.value = data.content.roleCharacter;
	} catch (error) {
		toast.error('Failed to fetch character information');
	}
};

const onCharacterChange = async () => {
	const character = roleCharacters.value.find(
		(char) => char.token === selectedCharacterToken.value
	);
	if (character) {
		await fetchCharacterInformation(character.token);
		const imageGenerationModel = selectedCharacter.value.imageGenerationModel;

		selectedModelName.value = imageGenerationModel ? imageGenerationModel.name : 'Unknown Model';

	} else {
		selectedModelName.value = '';
	}
};

const generateImage = async () => {
	if (!selectedCharacterToken.value || !prompt.value) {
		toast.error('Please select a character and enter a prompt');
		return;
	}

	isGenerating.value = true;

	const payload = {
		prompt: prompt.value,
		roleCharacterToken: selectedCharacterToken.value,
	};

	try {
		const response = await fetch('/v1/api/lab/generateimage', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'Authorization': `Bearer ${accessToken}`
			},
			body: JSON.stringify(payload),
		});
		const data = await response.json();
		const imageUrl = data.content;
		if (imageUrl) {
			generatedImages.value.unshift(imageUrl);
			toast.success('Image generated successfully');
		} else {
			toast.error('Failed to generate image');
		}
	} catch (error) {
		toast.error('Error generating image');
	} finally {
		isGenerating.value = false;
	}
};

onMounted(() => {
	fetchRoleCharacters();
});
</script>

<style scoped>
/* Add any additional styles if needed */
</style>
